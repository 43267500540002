import React from "react";
import { FormProvider, Controller } from "react-hook-form";
import { Stack, Button, Typography, Grid, Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  formHeader,
  formButtons,
  formTitle,
  formButtonsContainer,
  formContainer,
  mr15,
  my30,
} from "../../sharedStyles";
import GenericSubmitButton from "../../GenericSubmitButton";
import ErasableTextField from "../../ErasableTextField";
import { renderError } from "../../../utils/constants/forms";
import moment from "../../../utils/constants/momentConfig";

export default function CommunityServiceForm({
  activeService,
  handleDrawerClose,
  methods,
  onSubmit,
  setErrors,
  handleDeleteDialog,
}) {
  const handleError = (error, field) => {
    if (error === "invalidDate") {
      setErrors(true);
      methods.setError(field, {
        type: "custom",
        message: "Wrong format",
      });
    } else {
      setErrors(false);
      methods.clearErrors(field);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack direction="row" sx={formHeader}>
          <Grid container item sm={5}>
            <Typography sx={formTitle}>
              {activeService ? "Edit" : "Add"} Service Hours
            </Typography>
          </Grid>
          <Grid container item sm={7}>
            <Stack sx={formButtonsContainer} direction="row">
              <Button onClick={() => handleDrawerClose()} sx={formButtons}>
                Cancel
              </Button>
              {activeService && (
                <GenericSubmitButton
                  text="Delete"
                  submittingText="Deleting..."
                  type="button"
                  onClick={() => handleDeleteDialog(activeService)}
                />
              )}
              <GenericSubmitButton text="Save" submittingText="Saving..." />
            </Stack>
          </Grid>
        </Stack>
        <Box sx={formContainer}>
          <Stack direction="row">
            <Box sx={[mr15, { width: "100%" }]}>
              <Controller
                name="date"
                control={methods.control}
                defaultValue={
                  moment(activeService?.date).format("MM/DD/YYYY") ||
                  moment(new Date()).format("MM/DD/YYYY")
                }
                rules={{
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                  pattern: {
                    value:
                      /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                    message: "Invalid date.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    label="Date*"
                    id="date"
                    value={value}
                    onError={(newError) => handleError(newError, "date")}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!methods.formState.errors?.date}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                )}
              />

              {methods.formState.errors?.date &&
                renderError(methods.formState.errors?.date.message)}
            </Box>

            <TextField
              id="hours"
              label="Hours"
              fullWidth
              type="number"
              defaultValue={activeService?.hours || null}
              hasError={false}
              inputProps={{
                step: "0.01",
              }}
              error={!!methods.errors?.hours}
              {...methods.register("hours", {
                required: true,
              })}
              required
            />
          </Stack>
          <Box sx={my30}>
            <ErasableTextField
              id="description"
              label="Description"
              fullWidth
              type="text"
              defaultValue={activeService?.description || null}
              isMultiline
              lines={4}
            />
          </Box>
          <Box sx={my30}>
            <Stack direction="row">
              <ErasableTextField
                id="verified_by"
                label="Verified By"
                fullWidth
                inputSx={mr15}
                defaultValue={activeService?.verified_by || null}
              />
              <ErasableTextField
                id="supervisor"
                label="Supervisor"
                fullWidth
                defaultValue={activeService?.supervisor || null}
              />
            </Stack>
          </Box>
          <Box sx={my30}>
            <ErasableTextField
              id="requirement"
              label="Requirement Area"
              fullWidth
              defaultValue={activeService?.requirement || null}
            />
          </Box>
          <Box sx={{ borderBottom: "1px solid #BFC7CA", pb: "30px" }}>
            <ErasableTextField
              id="service_type"
              label="Service Type"
              fullWidth
              defaultValue={activeService?.service_type || null}
            />
          </Box>
          <Box sx={my30}>
            <ErasableTextField
              id="notes"
              label="Notes"
              fullWidth
              type="text"
              defaultValue={activeService?.notes || null}
              isMultiline
              lines={4}
            />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}
