import {
  Avatar,
  Box,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  boxAvatar,
  cellComment,
  tableBodyFontColor,
  tableBorderRight,
  avatarImage,
  postedIcon,
  displayFlex,
  boxAge,
  stickyCell,
} from "./Row.styles";
import { mr10, tableAvatar } from "../sharedStyles";
import moment from "../../utils/constants/momentConfig";
import prekDevelopmentService from "../../service/preKDevelopmentService";
import { SnackbarContext } from "../../context/SnackbarContext";
import Cell from "./Cell";
import { NavigationContext } from "../../context/NavigationContext";

export default function Row({
  student,
  studentsComments,
  setReloadComment,
  availableGrades,
  isReportCardPublished,
  managePermission,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const [rowComment, setRowComment] = useState("");
  const [rowPosted, setRowPosted] = useState("");
  const [rowId, setRowId] = useState("");
  const timeout = useRef();
  const schoolId = useParams().school_id;
  const navigationContext = useContext(NavigationContext);
  const parsedStudent = student[1][0]?.student;

  const navigateTo = (path) => {
    navigationContext.navigateTo(path);
  };

  function handleProfilePictureSrc(s) {
    if (s?.image_url) {
      return (
        <img alt="profile_image" src={s.image_url.url} style={avatarImage} />
      );
    }
    return (
      <Avatar sx={[tableAvatar, mr10]}>
        {s.last_name.charAt(0).toUpperCase()}
      </Avatar>
    );
  }

  function formatDateOfBirth(dob) {
    if (dob) {
      const m = moment(dob, "YYYY-MM-DD");
      const years = moment().diff(m, "years", false);
      const days = moment().diff(m.add(years, "years"), "days", false);
      return `${years} years ${days} days`;
    }
    return dob;
  }

  const handleCommentChange = async (event) => {
    const newComment = event.target.value;
    setRowComment(newComment);

    clearTimeout(timeout.current);
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });

    timeout.current = setTimeout(async () => {
      const response = await prekDevelopmentService
        .updateStudentDomainComment(rowId, newComment)
        .catch((err) =>
          snackbarContext.setSnackbar({
            message: err.response.data.message,
            severity: "error",
            open: true,
          })
        );

      if (response) {
        setRowComment(newComment);
        snackbarContext.setSnackbar({
          message: "Comment saved.",
          severity: "success",
          open: true,
        });
      }
    }, 1000);
  };

  useEffect(() => {
    (async () => {
      const studentRecord = studentsComments.find(
        (sc) => sc.student_id === parsedStudent.id
      );
      setRowComment(studentRecord?.comment || "");
      setRowId(studentRecord?.id);
      setRowPosted(studentRecord?.posted_at);
    })();
  }, [studentsComments]);

  return (
    <TableRow
      key={parsedStudent.id}
      id={rowPosted ? "" : rowId}
      className={`prek-tbody-row-${parsedStudent.id}`}
    >
      <>
        <TableCell sx={[tableBodyFontColor, tableBorderRight, stickyCell]}>
          <CheckCircleIcon
            style={postedIcon(rowPosted)}
            id={rowPosted ? "" : rowId}
          />
          <Box sx={displayFlex}>
            <Box
              sx={boxAvatar}
              onClick={() =>
                navigateTo(
                  `/school/${schoolId}/students/${parsedStudent.slug}/account`
                )
              }
            >
              {handleProfilePictureSrc(parsedStudent)}
              {`${parsedStudent.last_name}, ${parsedStudent.first_name}`}
            </Box>
            <Typography sx={boxAge}>
              {formatDateOfBirth(parsedStudent.date_of_birth)}
            </Typography>
          </Box>
        </TableCell>

        {student[1].map((element) => (
          <Cell
            key={element.id}
            element={element}
            setReloadComment={setReloadComment}
            availableGrades={availableGrades}
            studentId={parsedStudent.id}
            isReportCardPublished={isReportCardPublished}
            managePermission={managePermission}
          />
        ))}

        <TableCell>
          <TextField
            sx={cellComment}
            fullWidth
            placeholder="Progress Report Comment"
            disabled={isReportCardPublished || !managePermission}
            value={rowComment}
            onChange={(event) => handleCommentChange(event)}
          />
        </TableCell>
      </>
    </TableRow>
  );
}
