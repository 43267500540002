import { generateGUID } from "../../../../../utils/constants/utils";
import classesMap from "./Fields/classesMap";

export default class Section {
  constructor(_section) {
    const section = _section || {};
    this.id = section.id || null;
    this.sectionId = generateGUID();
    this.name = section.name || "Section";
    this.order = section.order || 0;
    if (section.form_fields) {
      section.fields = [];
      section.form_fields.forEach((field) => {
        section.fields.push(new classesMap[field.field_type](field));
      });
    }
    this.fields = section.fields || [];
    this.editable = section.editable === undefined ? true : section.editable;
    this.formBuilderOnly =
      section.formBuilderOnly || section.form_builder_only || false;
    this.isParentInfo = this.name?.includes("Parent") || false;
    this.isEmergencyContactInfo = this.name?.includes("Emergency") || false;
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      order: this.order,
      editable: this.editable,
      form_builder_only: this.formBuilderOnly,
      is_parent_info: this.isParentInfo,
      is_emergency_contact_info: this.isEmergencyContactInfo,
      form_fields_attributes: this.fields.map((field) =>
        new classesMap[field.type](field).serialize()
      ),
    };
  }
}
