import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import {
  reportCardDrawer,
  reportCardBox,
  mainTitle,
  downloadTitle,
  checkbox,
  box,
  schoolBox,
  title,
  studentDownloadBtn,
  gradeDownloadBtn,
  schoolZipDownloadBtn,
  schoolPdfDownloadBtn,
  selectBox,
  selectForm,
} from "./ReportCardDownloadDrawer.styles";
import { formButtons, formHeader, formTitle } from "../sharedStyles";
import { SnackbarContext } from "../../context/SnackbarContext";
import reportCardsService from "../../service/reportCardsService";
import appConfigService from "../../service/appConfigService";
import axios from "../../utils/axios-util";

export default function ReportCardDownloadDrawer({
  handleDrawerClose,
  term,
  schoolId,
  teacherFilterActive,
  teacherId,
}) {
  const snackbarContext = useContext(SnackbarContext);
  const reportCardPdfsEndpoint = "/api/report_card_pdfs";
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState(null);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [schoolZip, setSchoolZip] = useState(false);
  const [schoolPdf, setSchoolPdf] = useState(false);
  const [disableStudentDownload, setDisableStudentDownload] = useState(true);
  const [disableStudentSelect, setDisableStudentSelect] = useState(false);
  const [showStudentLoader, setShowStudentLoader] = useState(false);
  const [disableGradeDownload, setDisableGradeDownload] = useState(true);
  const [disableGradeSelect, setDisableGradeSelect] = useState(false);
  const [showGradeLoader, setShowGradeLoader] = useState(false);
  const [disableSchoolZipDownload, setDisableSchoolZipDownload] =
    useState(false);
  const [showSchoolZipLoader, setShowSchoolZipLoader] = useState(false);
  const [disableSchoolPdfDownload, setDisableSchoolPdfDownload] =
    useState(false);
  const [showSchoolPdfLoader, setShowSchoolPdfLoader] = useState(false);
  const [showBulkDownload, setShowBulkDownload] = useState(false);

  const handleStudentChange = (event) => {
    const selectedStudent = students.find((s) => s.id === event.target.value);
    setStudent(selectedStudent);
    setDisableStudentDownload(false);
    setGradeLevel(null);
    setDisableGradeDownload(true);
    setSchoolZip(false);
    setSchoolPdf(false);
  };

  const handleGradeChange = (event) => {
    setGradeLevel(event.target.value);
    setDisableGradeDownload(false);
    setStudent(null);
    setDisableStudentDownload(true);
    setSchoolZip(false);
    setSchoolPdf(false);
  };

  function handleSchoolZipDownload() {
    setSchoolZip(true);
    setDisableSchoolZipDownload(true);
    setGradeLevel(null);
    setDisableGradeDownload(true);
    setStudent(null);
    setDisableStudentDownload(true);
    setSchoolPdf(false);
  }

  function handleSchoolPdfDownload() {
    setSchoolPdf(true);
    setDisableSchoolPdfDownload(true);
    setGradeLevel(null);
    setDisableGradeDownload(true);
    setStudent(null);
    setDisableStudentDownload(true);
    setSchoolZip(false);
  }

  function showLoaderAndSelect(value, downloadOption, downloadType) {
    if (downloadOption === "student") {
      setShowStudentLoader(value);
    } else if (downloadOption === "grade_level") {
      setShowGradeLoader(value);
    } else if (downloadOption === "school" && downloadType === "zip") {
      setShowSchoolZipLoader(value);
    } else if (downloadOption === "school" && downloadType === "pdf") {
      setShowSchoolPdfLoader(value);
    } else {
      setShowStudentLoader(value);
      setShowGradeLoader(value);
      setShowSchoolZipLoader(value);
      setShowSchoolPdfLoader(value);
    }
    setDisableStudentSelect(value);
    setDisableGradeSelect(value);
    setDisableSchoolZipDownload(value);
    setDisableSchoolPdfDownload(value);
  }

  function snackBarError(message = "Something went wrong.") {
    snackbarContext.setSnackbar({
      severity: "error",
      open: true,
      message,
    });
  }

  const fetchFile = async (url, params, method) => {
    try {
      axios({
        url,
        method,
        responseType: "blob",
        data: params,
      }).then(
        async (response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          let filename = response.headers["content-disposition"]
            ?.split(";")[1]
            ?.split("=")[1]
            ?.replace(/['"]+/g, "");
          if (!filename) {
            filename = params.filename;
          }
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          showLoaderAndSelect(false);
          snackbarContext.setSnackbar({
            severity: "success",
            open: true,
            message: "Report card downloaded.",
          });
        },
        async (e) => {
          const errorMessage = JSON.parse(
            await e.response?.data?.text()
          )?.message;
          showLoaderAndSelect(false);
          snackBarError(errorMessage);
        }
      );
    } catch (e) {
      showLoaderAndSelect(false);
      snackBarError();
    }
  };

  const generateReportCardApi = async (params) => {
    try {
      await fetchFile(`${reportCardPdfsEndpoint}/generate`, params, "POST");
    } catch (e) {
      showLoaderAndSelect(false);
      snackBarError();
    }
  };

  const generateReportCard = async (downloadOption, downloadType) => {
    try {
      let filename = "";
      if (downloadOption === "grade_level") {
        filename = `${schoolId}_${gradeLevel}`;
      } else if (downloadOption === "student") {
        filename = `${schoolId}_${student.first_name}_${student.last_name}_${student.id}`;
      } else if (downloadOption === "school") {
        filename = `${schoolId}`;
      }
      showLoaderAndSelect(true, downloadOption, downloadType);
      await generateReportCardApi({
        student_id: student?.id,
        school_id: schoolId,
        term_id: term.id,
        filename,
        download_option: downloadOption,
        download_type: downloadType,
        grade_level: gradeLevel,
        rc_status: "2",
        ...(teacherFilterActive && { teacher_id: teacherId }),
      });
    } catch (e) {
      showLoaderAndSelect(false);
      snackBarError();
    }
  };

  useEffect(() => {
    (async () => {
      const response = await reportCardsService.fetchStudents({
        params: {
          school_id: schoolId,
          term_id: term.id,
          status: "2",
          from: "download_drawer",
        },
      });
      if (response.data) {
        // filter unique students
        const uniqueStudents = response.data.filter(
          (ele, index) =>
            response.data.findIndex((obj) => obj.id === ele.id) === index
        );
        const sortedStudents = uniqueStudents.sort((a, b) =>
          a.last_name > b.last_name ? 1 : -1
        );
        setStudents(sortedStudents);
        // get grade levels where the report card has been published
        // eslint-disable-next-line no-shadow
        let gradeLevels = response.data.map(
          // eslint-disable-next-line no-shadow
          (student) => student.grade_level
        );

        // get uniq grades and sorting
        gradeLevels = [...new Set(gradeLevels)].sort();
        // move TK and K to first in the list
        if (gradeLevels.includes("K")) {
          gradeLevels = gradeLevels.filter((e) => e !== "K");
          gradeLevels.unshift("K");
        } else if (gradeLevels.includes("TK")) {
          gradeLevels = gradeLevels.filter((e) => e !== "TK");
          gradeLevels.unshift("TK");
        }
        setGradeLevels(gradeLevels);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await appConfigService.fetchConfig();
      if (response.data) {
        setShowBulkDownload(
          response.data.find(
            (f) => f.key === "Enable report card bulk download"
          )?.value === "true"
        );
      }
    })();
  }, []);

  return (
    <Box sx={reportCardDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>Download Report Cards</Typography>
        <Stack direction="row">
          <Button sx={formButtons} onClick={() => handleDrawerClose()}>
            Close
          </Button>
        </Stack>
      </Stack>

      <Box sx={reportCardBox}>
        <Typography sx={mainTitle}>{term.name} Report Cards</Typography>
        <Typography sx={downloadTitle}>Download options:</Typography>

        {showBulkDownload ? (
          <>
            {/* school download pdf */}
            <Box sx={[box, schoolBox]}>
              <Box sx={checkbox}>{schoolPdf && <CheckIcon />}</Box>
              <Typography sx={title}>
                School PDF (all students combined in one file)
              </Typography>
              {showSchoolPdfLoader ? (
                <LoadingButton
                  sx={schoolPdfDownloadBtn}
                  loading
                  variant="outlined"
                >
                  Download
                </LoadingButton>
              ) : (
                <Button
                  disabled={disableSchoolPdfDownload}
                  variant="contained"
                  sx={schoolPdfDownloadBtn}
                  onClick={() => {
                    handleSchoolPdfDownload();
                    generateReportCard("school", "pdf");
                  }}
                >
                  Download
                </Button>
              )}
            </Box>

            {/* school download zip */}
            <Box sx={[box, schoolBox]}>
              <Box sx={checkbox}>{schoolZip && <CheckIcon />}</Box>
              <Typography sx={title}>
                School ZIP (all students as separate files in a .zip file)
              </Typography>
              {showSchoolZipLoader ? (
                <LoadingButton
                  sx={schoolZipDownloadBtn}
                  loading
                  variant="outlined"
                >
                  Download
                </LoadingButton>
              ) : (
                <Button
                  disabled={disableSchoolZipDownload}
                  variant="contained"
                  sx={schoolZipDownloadBtn}
                  onClick={() => {
                    handleSchoolZipDownload();
                    generateReportCard("school", "zip");
                  }}
                >
                  Download
                </Button>
              )}
            </Box>
          </>
        ) : (
          <Box sx={[box, schoolBox]}>
            <Typography>
              We have temporarily disabled School PDF and School ZIP file
              generation options. Please use the Grade Level or Student options
              to generate and download your report cards. We are working
              tirelessly to reactivate the additional options.
            </Typography>
          </Box>
        )}

        {/* grade download */}
        <Box sx={box}>
          <Box sx={checkbox}>{gradeLevel && <CheckIcon />}</Box>
          <Typography sx={title}>Grade Level</Typography>
          {showGradeLoader ? (
            <LoadingButton sx={gradeDownloadBtn} loading variant="outlined">
              Download
            </LoadingButton>
          ) : (
            <Button
              disabled={disableGradeDownload}
              variant="contained"
              sx={gradeDownloadBtn}
              onClick={() => generateReportCard("grade_level", "pdf")}
            >
              Download
            </Button>
          )}
        </Box>
        <Box sx={selectBox}>
          <FormControl sx={selectForm}>
            <InputLabel id="grade-select-label">Select Grade Level</InputLabel>
            <Select
              disabled={disableGradeSelect}
              labelId="grade-select-label"
              id="grade-select"
              value={gradeLevel || ""}
              label="Select Grade Level"
              onChange={handleGradeChange}
            >
              {gradeLevels.map((gl) => (
                <MenuItem key={gl} value={gl}>
                  {gl}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* student download */}
        <Box sx={box}>
          <Box sx={checkbox}>{student && <CheckIcon />}</Box>
          <Typography sx={title}>Student</Typography>
          {showStudentLoader ? (
            <LoadingButton sx={studentDownloadBtn} loading variant="outlined">
              Download
            </LoadingButton>
          ) : (
            <Button
              disabled={disableStudentDownload}
              variant="contained"
              sx={studentDownloadBtn}
              onClick={() => generateReportCard("student", "pdf")}
            >
              Download
            </Button>
          )}
        </Box>
        <Box sx={selectBox}>
          <FormControl sx={selectForm}>
            <InputLabel id="student-select-label">Select Student</InputLabel>
            <Select
              disabled={disableStudentSelect}
              labelId="student-select-label"
              id="student-select"
              value={student ? student.id : ""}
              label="Select Student"
              onChange={handleStudentChange}
            >
              {students.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {`${s.last_name}, ${s.first_name}`}
                </MenuItem>
              ))}
              {students.length === 0 && (
                <MenuItem>No report cards published.</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
