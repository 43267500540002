import FormPage from "./FormPage";
import FormProperties from "./FormProperties";

export const FormType = {
  INQUIRY: 1,
  PROCESS: 2,
};

export const FormStatus = {
  ENABLED: 1,
  DISABLED: 2,
};

export const FormStatusString = {
  1: "Active",
  2: "Inactive",
};
export class Form {
  constructor(_form) {
    const form = _form || {};
    this.id = form.id || null;
    this.name = form.name || "Untitled form";
    this.type = form.type || form.form_type || FormType.PROCESS;
    this.status = form.status || FormStatus.ENABLED;
    this.properties = form.properties || new FormProperties();
    const formPages = [];
    if (form.admission_form_pages) {
      form.admission_form_pages.forEach((element) => {
        formPages.push(new FormPage(element));
      });
    }
    this.formPages = form.formPages ||
      (formPages.length && formPages) || [new FormPage()];
    this.existingStudentData = form.existingStudentData || {};
    this.existingParentData = form.existingParentData || {};
    this.existingEmergencyContactData = form.existingEmergencyContactData || {};
    this.guardianCustomFields = form.guardianCustomFields || [];
    this.studentCustomFields = form.studentCustomFields || [];
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      admission_form_pages_attributes: this.formPages.map((formPage) =>
        new FormPage(formPage).serialize()
      ),
      form_type: this.type,
      status: this.status,
      properties: this.properties,
      existing_data: this.existingStudentData,
      existing_parent_data: this.existingParentData,
      existing_emergency_contact_data: this.existingEmergencyContactData,
      guardian_custom_fields: this.guardianCustomFields,
      student_custom_fields: this.studentCustomFields,
    };
  }
}
