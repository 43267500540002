import React, { useState, useEffect, useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  TextField,
  Stack,
  Box,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useFormContext } from "react-hook-form";
import lookupService from "../../../service/lookupService";
import { bgWhite, danger } from "../../sharedStyles";
import { PermissionsContext } from "../../../context/PermissionsContext";
import APP_PERMISSIONS from "../../../utils/constants/permissions";
import PERMISSION_TYPES from "../../../utils/constants/permission_types";
import ErasableTextField from "../../ErasableTextField";
import countriesService from "../../../service/countriesService";

function Demographics({ student }) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [languages, setLanguages] = useState([]);
  const [genders, setGenders] = useState([]);
  const [ethnicities, setEthnicities] = useState([]);
  const [denominations, setDenominations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [baptismYear, setBaptismYear] = useState(student.baptism_year);
  const [selectedDenomination, setSelectedDenomination] = useState(null);
  const [baptismYearChecked, setBaptismYearChecked] = useState(
    Boolean(student.baptism_year)
  );
  const { hasPermission } = useContext(PermissionsContext);

  const managePermission = hasPermission(
    APP_PERMISSIONS.STUDENT_ACCOUNT,
    PERMISSION_TYPES.MANAGE
  );

  useEffect(() => {
    setValue("denomination", student.denomination);
    setSelectedDenomination(student.denomination);
    Promise.all([
      lookupService.fetchLanguages(),
      lookupService.fetchGenders(),
      lookupService.fetchSchoolEthnicities(),
      lookupService.fetchDenomination(),
      countriesService.fetchAllCountries(),
    ]).then((response) => {
      setLanguages(response[0].data.data);
      setGenders(response[1].data.data);
      setEthnicities(response[2].data.data);
      setDenominations(response[3].data.data);
      setCountries(response[4].data);
      setStates(
        response[4].data.find(
          (country) => country.id === student.country_of_birth_id
        ).states
      );
    });
  }, [student]);

  const onBaptismYearHandleChange = (date) => {
    if (date) {
      setBaptismYear(date);
      setValue("baptism_year", date.format("MM/DD/YYYY"), {
        shouldDirty: true,
      });
    }
  };

  return (
    <Grid item xs={12} ml={{ sx: "10%", sm: "5%" }} textAlign="center">
      <Grid item sm={11} mb={4}>
        <Typography variant="h3">Demographics</Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Stack direction="column">
          <Box ml={3} mb={2} key="gender">
            <FormControl fullWidth>
              <InputLabel id="genderLabel">Gender</InputLabel>
              <Select
                labelId="genderLabel"
                id="gender"
                label="Gender"
                placeholder="Select"
                defaultValue={student.gender}
                {...register("gender")}
                disabled={!managePermission}
              >
                {genders.map((gender) => (
                  <MenuItem key={gender.id} value={gender.name}>
                    <Typography textAlign="left">{gender.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="ethnicity">
            <FormControl fullWidth>
              <InputLabel id="ethnicityLabel">Ethnicity</InputLabel>
              <Select
                labelId="ethnicityLabel"
                id="ethnicity"
                label="Ethnicity"
                defaultValue={student.ethnicity}
                {...register("ethnicity")}
                disabled={!managePermission}
              >
                {ethnicities.map((ethnicity) => (
                  <MenuItem key={ethnicity.id} value={ethnicity.name}>
                    <Typography textAlign="left">{ethnicity.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="language">
            <FormControl fullWidth>
              <InputLabel id="languageLabel">Language</InputLabel>
              <Select
                labelId="languageLabel"
                id="language"
                label="Language"
                defaultValue={student.language}
                {...register("language")}
                disabled={!managePermission}
              >
                {languages.map((language) => (
                  <MenuItem key={language.id} value={language.name}>
                    <Typography textAlign="left">{language.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="home_church">
            <FormControl fullWidth>
              <ErasableTextField
                id="home_church"
                label="Home Church"
                defaultValue={student.home_church}
                fullWidth
                disabled={!managePermission}
              />
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="denomination">
            <FormControl fullWidth>
              <InputLabel id="denominationLabel">Denomination</InputLabel>
              <Select
                labelId="denominationLabel"
                id="denomination"
                label="Denomination"
                value={selectedDenomination || ""}
                disabled={!managePermission}
                {...register("denomination", {
                  onChange: (e) => {
                    setSelectedDenomination(e.target.value);
                  },
                  required: "This field is required.",
                })}
              >
                {denominations.map((denomination) => (
                  <MenuItem key={denomination.id} value={denomination.name}>
                    <Typography textAlign="left">
                      {denomination.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="baptism_year">
            <Grid container item sm={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{ textAlign: "left" }}
                  control={
                    <Checkbox
                      disabled={!managePermission}
                      onChange={(event) => {
                        setBaptismYearChecked(event.target.checked);
                        if (!event.target.checked) {
                          setBaptismYear(null);
                          setValue("baptism_year", null, { shouldDirty: true });
                        }
                      }}
                      defaultChecked={baptismYearChecked}
                    />
                  }
                  label="Is baptized Seventh-day Adventist"
                />
              </FormGroup>
            </Grid>
            <Grid container item sm={11} xs={12} ml="auto">
              <DesktopDatePicker
                id="baptism_year"
                label="Baptism Date"
                onChange={onBaptismYearHandleChange}
                value={baptismYear}
                disabled={!managePermission || !baptismYearChecked}
                inputFormat="MM/DD/YYYY"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("baptism_year", {
                      pattern: {
                        value:
                          /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d{2}/,
                        message: "Invalid date.",
                      },
                    })}
                  />
                )}
              />
              {errors.baptism_year && (
                <Typography sx={danger}>
                  {errors.baptism_year.message}
                </Typography>
              )}
            </Grid>
          </Box>
          <Box ml={3} mb={2} key="one_roster_label" textAlign="left">
            <span style={{ fontWeight: "bold" }}>OneRoster Data </span>
            <span style={{ fontSize: 12 }}>(only required for ClassLink)</span>
          </Box>
          <Box ml={3} mb={2} key="countryOfBirth">
            <FormControl fullWidth>
              <InputLabel id="countryOfBirthLabel">Country of Birth</InputLabel>
              <Select
                labelId="countryOfBirthLabel"
                id="countryOfBirth"
                label="Country of Birth"
                placeholder="Select"
                defaultValue={student.country_of_birth_id}
                {...register("country_of_birth_id", {
                  onChange: async (e) => {
                    const statesByCountry =
                      await countriesService.fetchStatesByCountry(
                        e.target.value
                      );
                    setStates(statesByCountry.data);
                    if (student.country_of_birth_id !== e.target.value) {
                      setValue("state_of_birth_id", null);
                    }
                  },
                })}
                disabled={!managePermission}
              >
                {countries?.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    <Typography textAlign="left">{country.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="stateOfBirth">
            <FormControl fullWidth>
              <InputLabel id="stateOfBirthLabel">State of Birth</InputLabel>
              <Select
                labelId="stateOfBirthLabel"
                id="stateOfBirth"
                label="State of Birth"
                placeholder="Select"
                defaultValue={student.state_of_birth_id}
                {...register("state_of_birth_id")}
                disabled={!managePermission}
              >
                {states?.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    <Typography textAlign="left">{state.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="city_of_birth">
            <FormControl fullWidth>
              <ErasableTextField
                id="city_of_birth"
                label="City of Birth"
                defaultValue={student.city_of_birth}
                fullWidth
                disabled={!managePermission}
              />
            </FormControl>
          </Box>
          <Box ml={3} mb={2} key="city_of_birth">
            <FormControl fullWidth>
              <InputLabel
                id="public_school_residence_status"
                shrink
                sx={bgWhite}
              >
                Public School Residence Status
              </InputLabel>
              <Select
                labelId="public_school_residence_status"
                id="public_school_residence_status"
                defaultValue={student.public_school_residence_status}
                {...register("public_school_residence_status")}
              >
                <MenuItem value="administrative_unit_school_residence">
                  Resident of administrative unit and usual school attendance
                  area
                </MenuItem>
                <MenuItem value="administrative_unit_other_school_residence">
                  Resident of administrative unit, but of other school
                  attendance area
                </MenuItem>
                <MenuItem value="state_other_administrative_unit">
                  Resident of this state, but not of this administrative unit
                </MenuItem>
                <MenuItem value="administrative_unit_crosses_state_boundaries">
                  Resident of an administrative unit that crosses state
                  boundaries
                </MenuItem>
                <MenuItem value="another_state">
                  Resident of another state
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Demographics;
