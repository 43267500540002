/* eslint-disable no-param-reassign */
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { renderError } from "../../../../../utils/constants/forms";

export default function RadiobuttonListRenderer({
  field,
  properties,
  dataView,
}) {
  const {
    control,
    register,
    setValue,
    formState: { errors, isSubmitted },
    trigger,
  } = useFormContext();

  useEffect(() => {
    if (isSubmitted) trigger(field.fieldId);
  }, [field.isValid]);

  useEffect(() => {
    setValue(field.fieldId, field.response || field.existing_data || "", {
      shouldDirty: true,
    });
  }, []);

  return (
    <Box padding={1}>
      <FormControl
        sx={{ minWidth: "fit-content" }}
        fullWidth
        error={errors[field.fieldId]}
      >
        <FormLabel required={properties.isRequired}>{field.label}</FormLabel>
        <Controller
          name={field.fieldId}
          defaultValue={field.response}
          control={control}
          rules={{
            required: properties.isRequired && "This field is required",
          }}
          {...register(field.fieldId, {
            required: properties.isRequired && "This field is required",
            validate: field.isValid
              ? () => true
              : () => properties.validityMessage || "Invalid input",
          })}
          render={({ field1 }) => (
            <RadioGroup
              row
              {...field1}
              onChange={(event, value) => {
                setValue(field.fieldId, value, { shouldDirty: true });
                field.response = value;
                trigger(field.fieldId);
              }}
              defaultValue={field.response || field.existing_data || ""}
            >
              {properties.options.map((option) => (
                <FormControlLabel
                  value={option.value}
                  control={<Radio disabled={dataView} />}
                  sx={{
                    alignItems: "start",
                    ".MuiButtonBase-root": { color: "#22282C !important" },
                    ".MuiTypography-root": { color: "#3F464B !important" },
                  }}
                  label={
                    <Box mt={1}>
                      <Typography>{option.value}</Typography>
                    </Box>
                  }
                />
              ))}
            </RadioGroup>
          )}
        />
        <FormHelperText>{properties.supportingText}</FormHelperText>
      </FormControl>
      {errors[field.fieldId] && renderError(errors[field.fieldId].message)}
    </Box>
  );
}
