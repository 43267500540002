import {
  Button,
  ButtonGroup,
  CircularProgress,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Add, ArrowBackIos } from "@mui/icons-material";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { buttonWithStartIcon, loader, pageTabs } from "../../../sharedStyles";
import studentsService from "../../../../service/studentsService";
import QontoStepIcon from "../../../QontoStepIcon";
import QontoConnector from "../../../QontoConnector";
import { formatDateTime } from "../../../../utils/constants/utils";
import admissionProcessService from "../../../../service/admissionProcessService";
import lookupService from "../../../../service/lookupService";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import Guardian from "../../../StudentInfo/StudentActions/ActionDrawers/Guardian";
import { guardianDrawer } from "../../../StudentInfo/Account/ParentSection.styles";
import { processesButtonGroup } from "./StudentDetails.style";
import { CommonContext } from "../../../../context/CommonContext";
import GuardianStudentInfo from "./GuardianStudentInfo";

function QontoStepIconWrapper({ completed }) {
  return <QontoStepIcon checked={completed} />;
}
export default function StudentDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [studentDetails, setStudentDetails] = useState(null);
  const [processInstance, setProcessInstance] = useState(null);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [isGuardianDrawerOpen, setIsGuardianDrawerOpen] = useState(false);
  const [guardianId, setGuardianId] = useState(null);
  const [currentStaffId, setCurrentStaffId] = useState(null);
  const [processes, setProcesses] = useState([]);
  const [processInstancesList, setProcessInstancesList] = useState([]);
  const snackbarContext = useContext(SnackbarContext);
  const commonContext = useContext(CommonContext);
  const [processSteps] = useState([]);
  const [admissionGrade, setAdmissionGrade] = useState(
    processInstance?.admission_grade || ""
  );
  const [status, setStatus] = useState(processInstance?.step || "");

  const formattedParams = () => {
    let paramsUrl = "?";
    const schoolYearId = searchParams.get("school_year");
    const studentId = searchParams.get("student_id");
    const processId = searchParams.get("process_id");

    if (schoolYearId) {
      paramsUrl = paramsUrl.concat(`&school_year=${schoolYearId}`);
    }

    if (studentId) {
      paramsUrl = paramsUrl.concat(`&student_id=${studentId}`);
    }

    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  const tabs = [
    {
      index: 0,
      label: "Process Overview",
      url: `/school/${
        params.school_id
      }/admissions/student-details/process-overview${formattedParams()}`,
    },
    {
      index: 1,
      label: "Forms",
      url: `/school/${
        params.school_id
      }/admissions/student-details/forms${formattedParams()}`,
    },
    {
      index: 2,
      label: "Files",
      url: `/school/${
        params.school_id
      }/admissions/student-details/files${formattedParams()}`,
    },
    {
      index: 3,
      label: "Notes",
      url: `/school/${
        params.school_id
      }/admissions/student-details/notes${formattedParams()}`,
    },
  ];

  const [tabValue, setTabValue] = useState(tabs[0]);

  const getStudentDetails = () => {
    studentsService
      .fetchStudentDetails(searchParams.get("student_id"))
      .then((response) => {
        setStudentDetails(response.data);
      });
  };

  const sortStageAndElements = (stages) =>
    stages
      .sort(
        (a, b) =>
          a.admission_process_stage.order - b.admission_process_stage.order
      )
      .map((stage) => {
        // eslint-disable-next-line no-param-reassign
        stage.element_instances = stage.element_instances.sort(
          (a, b) =>
            a.stage_element.properties.order - b.stage_element.properties.order
        );
        return stage;
      });

  const isStageInstanceCompleted = (stageInstance) => {
    if (stageInstance.date_completed) {
      return { completed: true, date: stageInstance.date_completed };
    }
    let isCompleted = true;
    stageInstance.element_instances?.forEach((elementInstance) => {
      if (elementInstance.status !== "completed") isCompleted = false;
    });
    return {
      completed: isCompleted,
      date: isCompleted ? new Date().toLocaleDateString("en-US") : null,
    };
  };

  const mapStepsForStages = () => {
    const steps = [];
    processInstance?.stage_instances
      .filter((s) => s.admission_process_stage.include_in_time_line)
      .forEach((stageInstance) => {
        const stageCompleted = isStageInstanceCompleted(stageInstance);
        const stageStep = {
          completed: stageCompleted.completed,
          date: stageCompleted.date,
          name: stageInstance.admission_process_stage.name,
          id: `stage${stageInstance.id}`,
        };
        steps.push(stageStep);

        stageInstance.element_instances
          .filter((e) => e.properties.includeInTimeline)
          .forEach((elementInstance) => {
            const elementStep = {
              completed: elementInstance.status === "completed",
              date: elementInstance.date_completed,
              name: elementInstance.stage_element.name,
              id: `element${elementInstance.id}`,
            };
            steps.push(elementStep);
          });
      });
    return steps;
  };

  const getProcessInstanceDetails = () => {
    admissionProcessService
      .fetchAdmissionProcessInstanceDetails(searchParams.get("student_id"))
      .then((response) => {
        const updatedData = response.data.find(
          (process) =>
            process.admission_process_id ===
            parseInt(searchParams.get("process_id"), 10)
        );
        updatedData.stage_instances = sortStageAndElements(
          updatedData.stage_instances
        );
        setProcessInstancesList(response.data);
        const activeProcess = response.data.find(
          (pi) =>
            pi.admission_process.school_year_id ===
              parseInt(searchParams.get("school_year"), 10) &&
            !pi.hide_from_parent_portal
        );
        if (activeProcess) {
          searchParams.set("process_id", activeProcess.admission_process_id);
          setSearchParams(searchParams);
        }

        setProcessInstance(updatedData);
        setStatus(updatedData.step);
        mapStepsForStages();
        setCurrentStaffId(response.current_staff_id);
        setAdmissionGrade(updatedData.admission_grade);
      });
  };

  const fetchProcesses = () => {
    admissionProcessService
      .fetchAllProcesses(searchParams.get("school_year"))
      .then((response) => {
        setProcesses(response.data);
      });
  };

  const handleTabChange = (value, path) => {
    setTabValue(tabs[value]);
    navigate(path);
  };

  const navigateToAllStudents = () => {
    navigate(
      `/school/${
        params.school_id
      }/admissions/students/inquiries${formattedParams()}`
    );
  };

  const updateProcessInstance = (data) => {
    if (data.admission_grade) {
      setAdmissionGrade(data.admission_grade);
    }
    if (data.step) {
      setStatus(data.step);
    }
    snackbarContext.setSnackbar({
      message: "Saving...",
      severity: "info",
      open: true,
    });
    admissionProcessService
      .updateAdmissionGrade({
        processes_ids: processInstancesList
          .filter(
            (pi) =>
              !pi.hide_from_parent_portal &&
              pi.admission_process.school_year_id ===
                parseInt(searchParams.get("school_year"), 10)
          )
          .map((pi) => pi.id),
        ...data,
      })
      .then(() => {
        snackbarContext.setSnackbar({
          message: "Details saved.",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to save details",
          severity: "error",
          open: true,
        });
      });
  };

  const updateGuardian = (guardian) => {
    const updateStudentDetails = { ...studentDetails };
    const guardianStudentIndex =
      updateStudentDetails.guardians_students.findIndex(
        (gs) => gs.guardian.id === guardian.id
      );
    updateStudentDetails.guardians_students[guardianStudentIndex].guardian =
      guardian;
    setStudentDetails(updateStudentDetails);
  };

  const switchProcessInstance = (currentProcessId, newProcessId) => {
    admissionProcessService
      .switchProcessInstance(
        currentProcessId,
        newProcessId,
        searchParams.get("student_id")
      )
      .then(() => {
        snackbarContext.setSnackbar({
          message: "Process updated.",
          severity: "success",
          open: true,
        });
        searchParams.set("process_id", newProcessId);
        setSearchParams(searchParams);
        getProcessInstanceDetails();
        commonContext.fireProcessStarted();
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to update process.",
          severity: "error",
          open: true,
        });
      });
  };

  const toggleProcessInstanceVisibility = (processInstanceId) => {
    admissionProcessService
      .toggleProcessInstanceVisibility(processInstanceId)
      .then(() => {
        getProcessInstanceDetails();
        snackbarContext.setSnackbar({
          message: "Process hidden.",
          severity: "success",
          open: true,
        });
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to hide process.",
          severity: "error",
          open: true,
        });
      });
  };

  const addProcess = () => {
    admissionProcessService
      .startNewProcess(
        searchParams.get("school_year"),
        searchParams.get("student_id")
      )
      .then(() => {
        snackbarContext.setSnackbar({
          message: "Process added.",
          severity: "success",
          open: true,
        });
        getProcessInstanceDetails();
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to add process.",
          severity: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    const getTabFromUrl = tabs.find((tab) =>
      location.pathname.includes(tab.url.split("?")[0])
    );
    if (getTabFromUrl) setTabValue(getTabFromUrl);
    else navigate(tabs[0].url);

    getStudentDetails();
    getProcessInstanceDetails();
    lookupService.fetchGradeLevels().then((response) => {
      setGradeLevels(response.data.data);
    });
    fetchProcesses();
  }, [searchParams.get("school_year"), searchParams.get("process_id")]);

  return !studentDetails ? (
    <CircularProgress color="inherit" size={100} sx={loader} />
  ) : (
    <Grid container>
      <Grid
        item
        xs={4}
        container
        sx={{ backgroundColor: "#FAFAFA" }}
        height="100%"
        pb={3}
      >
        <Grid item xs={8} pl={2} container alignItems="center">
          <Button
            onClick={navigateToAllStudents}
            variant="text"
            startIcon={<ArrowBackIos />}
          >
            <Typography textTransform="none">All Students</Typography>
          </Button>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <Select
              id="status"
              label="Status"
              sx={{
                backgroundColor: "#123762",
                color: "white",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
              }}
              value={status}
              onChange={(e) => updateProcessInstance({ step: e.target.value })}
            >
              <MenuItem key="1" value="in_process">
                In Process
              </MenuItem>
              <MenuItem key="2" value="in_review">
                In Review
              </MenuItem>
              <MenuItem key="3" value="waiting_list">
                Waiting List
              </MenuItem>
              <MenuItem key="4" value="accepted">
                Accepted
              </MenuItem>
              <MenuItem key="5" value="denied">
                Denied
              </MenuItem>
              <MenuItem key="6" value="withdrawn">
                Withdrawn
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} container my={3} mx={3} columnSpacing={2}>
          <Grid item xs={2} container justifyContent="end">
            <Avatar sx={{ width: "4rem", height: "4rem" }}>
              <img
                width="100%"
                src="/assets/default_avatar.jpg"
                alt="student profile"
              />
            </Avatar>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <Typography color="#FE8324" fontWeight={500}>
              {studentDetails.enrolled_in_current_school && "RETURNING"}
            </Typography>
            <Typography fontSize={24} color="black">
              {`${studentDetails.first_name} ${studentDetails.last_name}`}
            </Typography>
            {/* <Typography color="black">Language: English</Typography> */}
          </Grid>
          <Grid item xs={4} container alignItems="center" justifyContent="end">
            <FormControl fullWidth>
              <InputLabel id="gradeLevelLabel">Grade Level</InputLabel>
              <Select
                labelId="gradeLevelLabel"
                id="grade_level"
                label="Grade level"
                value={admissionGrade}
                onChange={(e) => {
                  updateProcessInstance({ admission_grade: e.target.value });
                }}
              >
                {gradeLevels.map((gradeLevel) => (
                  <MenuItem key={gradeLevel.name} value={gradeLevel.name}>
                    {gradeLevel.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {processInstancesList.map(
            (_processInstance) =>
              _processInstance.admission_process.school_year_id ===
                parseInt(searchParams.get("school_year"), 10) && (
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  mt={2}
                  spacing={2}
                  key={_processInstance.id}
                >
                  <Grid item xs={8} container alignItems="center">
                    <FormControl fullWidth>
                      <InputLabel id="processLabel">Process</InputLabel>
                      <Select
                        labelId="processLabel"
                        id="process"
                        label="Process"
                        defaultValue={_processInstance.admission_process_id}
                        disabled={_processInstance.hide_from_parent_portal}
                        onChange={(e) => {
                          switchProcessInstance(
                            _processInstance.admission_process_id,
                            e.target.value
                          );
                        }}
                      >
                        {processes.map((process) => (
                          <MenuItem
                            key={process.id}
                            value={process.id}
                            disabled={
                              processInstancesList.find(
                                (pi) =>
                                  pi.admission_process_id === process.id &&
                                  pi.id !== _processInstance.id
                              ) !== undefined
                            }
                          >
                            <Typography textAlign="left">
                              {process.name}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} container alignItems="center">
                    <Button
                      variant="text"
                      onClick={() =>
                        toggleProcessInstanceVisibility(_processInstance.id)
                      }
                      sx={{ ...buttonWithStartIcon, fontSize: "16px" }}
                    >
                      {_processInstance.hide_from_parent_portal
                        ? "Show"
                        : "Hide"}
                    </Button>
                  </Grid>
                </Grid>
              )
          )}
          <Grid item xs={12} container alignItems="center" mt={2}>
            <Button
              variant="text"
              onClick={addProcess}
              sx={{ ...buttonWithStartIcon, fontSize: "16px" }}
              startIcon={<Add />}
              disabled={processes.length === processInstancesList.length}
            >
              Start New Process
            </Button>
          </Grid>
        </Grid>
        {studentDetails.guardians_students?.map(
          (guardiansStudents) =>
            guardiansStudents.guardian && (
              <GuardianStudentInfo
                guardian={guardiansStudents.guardian}
                setGuardianId={setGuardianId}
                setIsGuardianDrawerOpen={setIsGuardianDrawerOpen}
                hasPortalAccess={guardiansStudents.has_portal_access}
              />
            )
        )}
      </Grid>
      <Grid item xs={8} p={5}>
        <Grid item xs={12} overflow="auto">
          <Stepper
            alternativeLabel
            activeStep={2}
            connector={<QontoConnector />}
          >
            {processSteps.map((step) => (
              <Step completed={step.completed} key={step.id}>
                <StepLabel
                  sx={{ "& .MuiStepLabel-label": { mt: "0 !important" } }}
                  StepIconComponent={QontoStepIconWrapper}
                >
                  <Typography variant="caption" display="block" gutterBottom>
                    {step.date ? formatDateTime(step.date) : "-"}
                  </Typography>
                  {step.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12} mt={7}>
          <Tabs value={tabValue?.index} sx={pageTabs}>
            {tabs?.map((tab) => (
              <Tab
                key={tab.index}
                value={tab.index}
                label={tab.label}
                onClick={() => handleTabChange(tab.index, tab.url)}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} textAlign="center" mt={2}>
          {tabValue.index === 0 && (
            <ButtonGroup variant="contained" sx={{ borderRadius: "15px" }}>
              {processInstancesList
                .filter(
                  (pi) =>
                    !pi.hide_from_parent_portal &&
                    pi.admission_process.school_year_id ===
                      parseInt(searchParams.get("school_year"), 10)
                )
                .map((_processInstance, index) => (
                  <Button
                    key={_processInstance.id}
                    sx={processesButtonGroup(
                      _processInstance.admission_process_id ===
                        processInstance.admission_process_id,
                      index === 0,
                      index ===
                        processInstancesList.filter(
                          (pi) =>
                            !pi.hide_from_parent_portal &&
                            pi.admission_process.school_year_id ===
                              parseInt(searchParams.get("school_year"), 10)
                        ).length -
                          1
                    )}
                    onClick={() => {
                      setProcessInstance(_processInstance);
                    }}
                  >
                    {_processInstance.admission_process.name}
                  </Button>
                ))}
            </ButtonGroup>
          )}
        </Grid>
        <Grid item xs={12}>
          <Outlet
            context={[
              processInstance,
              studentDetails.guardians_students?.map((gs) => gs.guardian),
              setProcessInstance,
              currentStaffId,
            ]}
          />
        </Grid>
      </Grid>
      <Drawer open={isGuardianDrawerOpen} anchor="right" sx={guardianDrawer}>
        <Guardian
          setIsGuardianDrawerOpen={setIsGuardianDrawerOpen}
          currentGuardianId={guardianId}
          studentSlug={studentDetails.slug}
          onUpdateGuardian={updateGuardian}
          setSnackBarOpen={snackbarContext.setSnackbar}
        />
      </Drawer>
    </Grid>
  );
}
