const transcriptDrawer = { width: "800px" };

const transcriptBox = {
  mt: "40px",
  ml: "75px",
  mr: "75px",
};

const checkbox = {
  position: "relative",
  borderRadius: "50%",
  width: 45,
  height: 45,
  border: "1px solid",
  mr: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const box = {
  mt: "30px",
  pb: "10px",
  display: "flex",
};

const schoolBox = {
  borderBottom: "1px #BFC7CA solid",
};

const title = {
  pt: "10px",
  color: "black",
  fontSize: "16px",
};

const studentGenerateBtn = {
  background: "#2196F3",
  "&:hover": { background: "#2196F3" },
  ml: "425px !important",
  textTransform: "none",
  margin: "4px",
  "& .MuiCircularProgress-root": {
    color: "white",
  },
};

const allGradeLevelsGenerateBtn = {
  ...studentGenerateBtn,
  ml: "315px !important",
};

const gradeLevelDownloadBtn = {
  ...studentGenerateBtn,
  ml: "395px !important",
};

const selectBox = {
  borderBottom: "1px #BFC7CA solid",
  pb: "10px",
};

const selectForm = {
  ml: "50px",
  width: "400px",
};

export {
  allGradeLevelsGenerateBtn,
  gradeLevelDownloadBtn,
  studentGenerateBtn,
  transcriptBox,
  box,
  selectBox,
  schoolBox,
  selectForm,
  title,
  checkbox,
  transcriptDrawer,
};
