import { styled } from "@mui/material/styles";
import { Tabs } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppTheme from "../../../utils";

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})((props) => {
  const drawerWidth = useMediaQuery(AppTheme.breakpoints.down("xl"))
    ? 350
    : 400;

  return {
    flexGrow: 1,
    transition: AppTheme.transitions.create("margin", {
      easing: AppTheme.transitions.easing.sharp,
      duration: AppTheme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(props.open && {
      transition: AppTheme.transitions.create("margin", {
        easing: AppTheme.transitions.easing.easeOut,
        duration: AppTheme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    paddingRight: props.open && "40px",
  };
});

const CssTabs = styled(Tabs)({
  "&.MuiTabs-root": {},
  "& .MuiTab-root": {
    padding: 0,
    minWidth: 0,
    marginRight: 24,
    textTransform: "none",
    color: AppTheme.palette.primary.main,
  },
  "& .MuiTab-root.Mui-selected": {},
});

const studentInfoHighlightedLink = {
  color: "#5077A4",
  fontWeight: "bold",
  fontSize: 14,
  display: "flex",
  alignItems: "start",
};

const studentInfoTabBox = { width: "100%" };

const studentInfoTabContainer = (open) => ({
  px: open ? "25px !important" : "65px !important",
});

const studentInfoTabBorder = {
  borderBottom: 1,
  borderColor: "primary.main",
};

const studentTranscriptContainer = {
  maxWidth: "1200px",
};

const transcriptModalPreview = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
  height: "95%",
  width: "95%",
};

export {
  Main,
  CssTabs,
  studentInfoHighlightedLink,
  studentInfoTabBorder,
  studentInfoTabBox,
  studentInfoTabContainer,
  studentTranscriptContainer,
  transcriptModalPreview,
};
