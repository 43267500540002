/* eslint-disable no-restricted-syntax */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  useSearchParams,
  Link as RouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { errorBadge, submitButton } from "../FormBuilder/FormBuilder.style";
import { FormBuilderContext } from "../FormBuilder/FormBuilderContext";
import FormSectionRenderer from "./FormSectionRenderer";
import admissionFormService from "../../../../service/admissionFormService";
import dataTypeClassMap from "../FormBuilder/Models/Fields/dataTypeClassMap";
import { SnackbarContext } from "../../../../context/SnackbarContext";
import { Form } from "../FormBuilder/Models/Form";
import { buttonWithStartIcon, formTitle, loader } from "../../../sharedStyles";
import { QuickBarContext } from "../../../../context/QuickBarContext";
import { sortSectionsAndFields } from "../FormBuilder/FormBuilderUtils";
import DeleteFormResponse from "../../DeleteFormResponse";

export default function FormRenderer({
  submitButtonRef,
  onSaved,
  studentId,
  onBuilder,
  inquiryFormView = false,
  preview = false,
  dataView,
  returnUrl,
  returnPrompt,
  guardianView = false,
}) {
  const printComponent = useRef(null);
  const quickBarContext = useContext(QuickBarContext);
  const handlePrint = useReactToPrint({
    content: () => printComponent.current.cloneNode(true),
    pageStyle: `
      @page { size: "auto";  margin: 5mm; }
      @media print {
        .report-footer{
          visibility: visible;
        }
      }
    `,
  });

  const params = useParams();
  const guardianId = params.id;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formBuilderContext = useContext(FormBuilderContext);
  const snackbarContext = useContext(SnackbarContext);
  const { form, currentPageIndex, updateForm, updateCurrentPageIndex } =
    formBuilderContext;
  const methods = useForm({ mode: "onSubmit" });
  const {
    control,
    formState: { errors },
    trigger,
  } = methods;
  const formValues = useWatch({
    control,
  });

  const [fieldsMap, setFieldsMap] = useState();

  const [loading, setLoading] = useState(false);

  const previousValuesRef = useRef(formValues);

  const [failedValidation, setFailedValidation] = useState(null);

  const formattedParams = () => {
    let paramsUrl = "?";
    const processId = searchParams.get("process_id");
    if (processId) {
      paramsUrl = paramsUrl.concat(`&process_id=${processId}`);
    }

    return paramsUrl;
  };

  let parentSectionIndex = -1;
  let emergencyContactSectionIndex = -1;

  const onSubmit = (data) => {
    if (onBuilder) return;
    const formResponse = {};
    formResponse.admission_form_id = form.id;
    formResponse.field_responses_attributes = [];
    formResponse.custom_field_responses = [];
    Object.keys(data).forEach((key) => {
      const fieldElement = fieldsMap.get(key).element;
      const fieldResponse = {};
      fieldResponse[`${fieldElement.dataType}`] = dataTypeClassMap[
        fieldElement.dataType
      ](data[key]);
      // if (!fieldElement.customFieldId) {
      fieldResponse.form_field_id =
        fieldElement.sourceFieldId || fieldElement.id;
      formResponse.field_responses_attributes.push(fieldResponse);
      // } else {
      //   fieldResponse.custom_field_id = fieldElement.customFieldId;
      //   fieldResponse.response_type = fieldElement.customFieldResponseType;
      //   formResponse.custom_field_responses.push(fieldResponse);
      // }
    });
    const isLastPage = form.formPages.length === currentPageIndex + 1;
    const hasError = Object.keys(errors).length > 0;
    const partiallyFilled = !isLastPage && !hasError;
    admissionFormService
      .saveFormResponse(
        formResponse,
        searchParams.get("element_instance_id"),
        partiallyFilled,
        studentId
      )
      .then((response) => {
        if (onSaved) {
          onSaved(response.data.id);
        } else {
          snackbarContext.setSnackbar({
            message: "Form submitted.",
            severity: "success",
            open: true,
          });
          if (!partiallyFilled && guardianView) {
            navigate(
              `/guardian/${guardianId}/students/${studentId}/home/enrollments/checklist${formattedParams()}`
            );
          }
        }
      })
      .catch(() => {
        snackbarContext.setSnackbar({
          message: "Failed to submit form.",
          severity: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    if (searchParams.get("form_id") && !dataView) {
      setLoading(true);
      updateForm(null);
      (async () => {
        const response = await admissionFormService.getFormRender(
          searchParams.get("form_id")
        );
        setLoading(false);
        response.data.admission_form_pages =
          response.data.admission_form_pages.map((page) => {
            const updatedPage = page;
            updatedPage.form_sections = sortSectionsAndFields(
              page.form_sections
            );
            return updatedPage;
          });

        const formObj = new Form(response.data);
        updateForm(formObj);
      })();
    }
  }, [searchParams.get("form_id")]);

  const evaluateValidity = async (field) => {
    for (const dependentId of field.properties.validityDependents) {
      const dependent = fieldsMap.get(dependentId).element;
      let script = "";
      let variables = "";
      for (const dependeeId of dependent.properties.validityDependee) {
        const dependee = fieldsMap.get(dependeeId).element;
        const dependeeValue =
          dependee.type === "date-field"
            ? new Date(formValues[dependee.fieldId]).getTime()
            : formValues[dependee.fieldId];

        variables = `${variables} \n var ${dependee.fieldId}= '${dependeeValue}' `;
      }
      script += variables;
      script = `${script} \n return ${dependent.properties.validityConditionJS}`;

      try {
        // eslint-disable-next-line no-new-func
        const fun = new Function(script);
        const valid = fun();

        const { fieldIndex, sectionIndex, pageIndex } =
          fieldsMap.get(dependentId);
        const formUpdate = { ...form };
        formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
          fieldIndex
        ].isValid = valid;
        updateForm(formUpdate);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const evaluateVisibility = async (field) => {
    for (const dependentId of field.properties.visibilityDependents) {
      const dependent = fieldsMap.get(dependentId).element;
      let script = "";
      let variables = "";
      for (const dependeeId of dependent.properties.visibilityDependee) {
        const dependee = fieldsMap.get(dependeeId).element;
        variables = `${variables} \n var ${dependee.fieldId}= '${
          formValues[dependee.fieldId]
        }' `;
      }
      script += variables;
      script = `${script} \n return ${dependent.properties.visibilityConditionJS}`;

      try {
        // eslint-disable-next-line no-new-func
        const fun = new Function(script);
        const visible = fun();

        const { fieldIndex, sectionIndex, pageIndex } =
          fieldsMap.get(dependentId);
        const formUpdate = { ...form };
        formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
          fieldIndex
        ].isVisible = visible;
        if (!visible) {
          methods.unregister(
            formUpdate.formPages[pageIndex].sections[sectionIndex].fields[
              fieldIndex
            ].fieldId
          );
        }
        updateForm(formUpdate);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onFieldValueChange = (field) => {
    evaluateVisibility(field);
    if (field.isVisible) evaluateValidity(field);
  };

  useEffect(() => {
    const isPreviousValuesNotEmpty =
      Object.keys(previousValuesRef.current).length !== 0;

    if (isPreviousValuesNotEmpty && !inquiryFormView) {
      const changedValues = Object.entries(formValues).filter(
        ([key, value]) =>
          previousValuesRef.current[key] !== value &&
          previousValuesRef.current[key] !== undefined &&
          form.formPages[currentPageIndex]?.sections
            .flatMap((section) => section.fields.map((field) => field.fieldId))
            .includes(key)
      );
      changedValues.forEach(([key]) => {
        const changedField = form.formPages[currentPageIndex]?.sections
          .flatMap((section) => section.fields)
          .find((field) => field.fieldId === key);
        if (changedField && changedField.properties?.isRequired) {
          trigger(changedField.fieldId);
          onFieldValueChange(changedField);
        }
      });
    }
    previousValuesRef.current = formValues;
  }, [formValues, currentPageIndex, inquiryFormView, form]);

  const nextButton = (
    <Button variant="contained" sx={submitButton}>
      Next
    </Button>
  );

  const previousButton = (
    <Button variant="contained" sx={submitButton}>
      Previous
    </Button>
  );

  useEffect(() => {
    if (fieldsMap && form) {
      form.formPages[currentPageIndex].sections.forEach((section) => {
        section.fields.forEach((element) => {
          evaluateValidity(element);
          evaluateVisibility(element);
        });
      });
    }
  }, [fieldsMap]);

  useEffect(() => {
    if (form?.id && !fieldsMap) {
      setFieldsMap(
        new Map(
          form.formPages.flatMap((_page, pageIndex) =>
            _page.sections.flatMap((section, sectionIndex) =>
              section.fields.map((field, fieldIndex) => [
                field.fieldId,
                { element: field, fieldIndex, sectionIndex, pageIndex },
              ])
            )
          )
        )
      );
    }
  }, [form]);

  const createRemoveErrorBadges = (pageFieldCounts) => {
    for (const key in pageFieldCounts) {
      if (pageFieldCounts[key] > 0) {
        const paginationItems = document.getElementsByClassName(
          "MuiPaginationItem-root"
        );
        let keyNumber = Number(key);
        if (!guardianView && !dataView && !preview) {
          keyNumber += 1;
        }
        const childToStyle = paginationItems[keyNumber];

        if (childToStyle) {
          const badge = document.createElement("span");
          badge.textContent = pageFieldCounts[key];
          Object.keys(errorBadge).forEach((property) => {
            badge.style[property] = errorBadge[property];
          });
          badge.id = `page-number-${keyNumber}-badge`;
          badge.classList.add("error-badge-on-pagination");

          childToStyle.style.position = "relative";

          childToStyle.appendChild(badge);
        }
      } else {
        const paginationItems = document.getElementsByClassName(
          "MuiPaginationItem-root"
        );
        let keyNumber = Number(key);
        if (!guardianView && !dataView && !preview) {
          keyNumber += 1;
        }
        const childToStyle = paginationItems[keyNumber];
        const badgeElement = document.getElementById(
          `page-number-${keyNumber}-badge`
        );
        if (badgeElement) childToStyle.removeChild(badgeElement);
      }
    }
  };

  const handleFields = (clicked, onlyValidations = false, hasErrors = {}) => {
    const fieldIds = hasErrors;
    let pageFieldCounts = [];
    if (fieldIds.length) {
      pageFieldCounts = form.formPages.map((_page) => {
        const count = _page.sections.reduce((acc, section) => {
          const matchingFieldsCount = section.fields.filter((field) =>
            fieldIds.includes(field.fieldId)
          ).length;
          return acc + matchingFieldsCount;
        }, 0);

        return count;
      });
      const isLastPage = form?.formPages?.length === currentPageIndex + 1;
      if (clicked && pageFieldCounts[currentPageIndex] === 0) {
        if (!isLastPage && !onlyValidations) {
          updateCurrentPageIndex(currentPageIndex + 1);
        }
        if (!onlyValidations) {
          onSubmit(formValues);
        }
      }
      createRemoveErrorBadges(pageFieldCounts);
    } else {
      const isLastPage = form?.formPages?.length === currentPageIndex + 1;
      if (clicked && pageFieldCounts.length === 0) {
        if (!isLastPage && !onlyValidations) {
          updateCurrentPageIndex(currentPageIndex + 1);
        }
        if (!onlyValidations) {
          onSubmit(formValues);
        }
      }
      const badgeElements = document.querySelectorAll(
        ".error-badge-on-pagination"
      );
      badgeElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    }
  };

  useEffect(() => {
    if (failedValidation || Object.keys(errors).length > 0) {
      handleFields(false, true, Object.keys(errors));
    }
  }, [failedValidation, Object.keys(errors).length]);

  useEffect(() => {
    if (quickBarContext.printReport) {
      handlePrint();
      quickBarContext.cleanPrintReport();
    }
  }, [quickBarContext.printReport]);

  return loading || !form ? (
    <CircularProgress color="inherit" size={100} sx={loader} />
  ) : (
    <FormProvider {...methods}>
      <form
        style={{
          width: "100%",
          backgroundColor:
            guardianView || dataView || preview ? "#ffffff" : "inherit",
        }}
        onSubmit={inquiryFormView ? methods.handleSubmit(onSubmit) : null}
      >
        {(guardianView || dataView) && !preview ? (
          <>
            <Grid py={2} ml={3} container>
              <Link
                to={
                  dataView
                    ? returnUrl
                    : `/guardian/${guardianId}/students/${studentId}/home/enrollments/checklist${formattedParams()}`
                }
                underline="none"
                component={RouterLink}
              >
                <Button
                  variant="text"
                  startIcon={<ArrowBackIosIcon size="small" />}
                  sx={buttonWithStartIcon}
                >
                  Return To {dataView ? returnPrompt : "Checklist"}
                </Button>
              </Link>
            </Grid>
            <Box ref={printComponent}>
              <Grid py={2} container justifyContent="center">
                <Typography color="black" sx={formTitle}>
                  {form?.name}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                sx={{
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(0, 0, 0, 0.23) !important",
                    borderWidth: "1px",
                  },
                }}
              >
                {form.formPages[currentPageIndex]?.sections.map(
                  (section, index) => {
                    if (section.isParentInfo) {
                      parentSectionIndex += 1;
                    }
                    if (section.isEmergencyContactInfo) {
                      emergencyContactSectionIndex += 1;
                    }
                    return (
                      !section.formBuilderOnly && (
                        <Grid
                          item
                          xs={12}
                          display="grid"
                          justifyContent="center"
                        >
                          <FormSectionRenderer
                            section={section}
                            sectionIndex={index}
                            dataView={dataView}
                            formattedView={
                              guardianView ||
                              dataView ||
                              preview ||
                              inquiryFormView
                            }
                            guardianView={guardianView}
                            parentSectionIndex={parentSectionIndex}
                            emergencyContactSectionIndex={
                              emergencyContactSectionIndex
                            }
                          />
                        </Grid>
                      )
                    );
                  }
                )}
              </Grid>
            </Box>
          </>
        ) : (
          form.formPages.map((_page, pageIndex) =>
            _page.sections.map(
              (section, sectionIndex) =>
                !section.formBuilderOnly && (
                  <Box
                    display={
                      pageIndex !== currentPageIndex
                        ? "none !important"
                        : "flex"
                    }
                    justifyContent="center"
                  >
                    <FormSectionRenderer
                      key={`page_${_page.id}-section_${section.id}`}
                      section={section}
                      sectionIndex={sectionIndex}
                      formattedView={
                        guardianView || dataView || preview || inquiryFormView
                      }
                      dataView={dataView}
                    />
                  </Box>
                )
            )
          )
        )}
        {form && (
          <Grid container justifyContent="center" pb={3}>
            {(guardianView || dataView || preview) && (
              <Grid item xs={12} display="grid" justifyContent="center" py={2}>
                <Divider sx={{ width: "800px" }} />
              </Grid>
            )}
            {form.formPages.length > 1 && (
              <Pagination
                page={currentPageIndex + 1}
                count={form.formPages.length}
                onChange={(e, v) => updateCurrentPageIndex(v - 1)}
                // disabled={!preview && !canGoToNextPage && Object.keys(errors).length > 0}
                {...((guardianView || dataView || preview) && {
                  hideNextButton: true,
                  hidePrevButton: true,
                  size: "large",
                  variant: "outlined",
                })}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: previousButton, next: nextButton }}
                    {...item}
                  />
                )}
              />
            )}

            {!dataView &&
              (guardianView || preview ? (
                <Box ml={guardianView || preview ? 3 : 0}>
                  <Button
                    ref={submitButtonRef}
                    variant="contained"
                    sx={{ ...submitButton, display: submitButtonRef && "none" }}
                    type="button"
                    onClick={() => {
                      form?.formPages?.forEach((_page, index) => {
                        if (index === currentPageIndex) {
                          const validFields = [];
                          _page.sections.forEach((section) => {
                            section.fields.forEach((field) => {
                              if (
                                guardianView &&
                                Object.keys(formValues).length === 0
                              ) {
                                formValues[field.fieldId] =
                                  field.response || "";
                              }
                              validFields.push(trigger(field.fieldId));
                            });
                          });

                          Promise.all(validFields).then((res) => {
                            const hasPassedValidation = res.every(
                              (element) => element === true
                            );

                            if (hasPassedValidation) {
                              handleFields(true);
                            } else {
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              });
                              setFailedValidation(true);
                            }
                          });
                        }
                      });
                    }}
                  >
                    {form.formPages.length === currentPageIndex + 1
                      ? "Submit"
                      : "Next"}
                  </Button>
                </Box>
              ) : (
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  sx={{ ...submitButton, display: submitButtonRef && "none" }}
                >
                  Submit
                </Button>
              ))}
          </Grid>
        )}
      </form>

      {form && searchParams.get("form_response_id") && (
        <Grid container justifyContent="center" pb={3}>
          <Grid>
            <DeleteFormResponse
              formResponseId={searchParams.get("form_response_id")}
              typeDeleteToConfirm={false}
            >
              <Button color="error" variant="contained">
                Delete
              </Button>
            </DeleteFormResponse>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
}
