import axios from "../utils/axios-util";

const communityServicesEndpoint = "/api/community_services";

const createCommunityService = async (params) => {
  const { data } = await axios.post(communityServicesEndpoint, params);

  if (!data.data) {
    console.error("Error creating record");
    return [];
  }
  return data;
};

const updateCommunityService = async (params) => {
  const { data } = await axios.put(
    `${communityServicesEndpoint}/${params.id}`,
    params
  );

  if (!data.data) {
    console.error("Error updating record");
    return [];
  }
  return data;
};

const deleteCommunityService = async (params) => {
  const { data } = await axios.delete(
    `${communityServicesEndpoint}/${params.id}`
  );

  if (!data.data) {
    console.error("Error deleting record");
    return [];
  }
  return data;
};

export default {
  createCommunityService,
  updateCommunityService,
  deleteCommunityService,
};
