const transcriptPreviewClose = {
  position: "absolute",
  right: 0,
  mr: "20px",
};

const transcriptPreviewDownload = {
  position: "absolute",
  right: 100,
  mr: "20px",
};

const previewText = {
  position: "sticky",
  top: "300px",
  textAlign: "center",
  color: "rgba(255, 206.13, 229.59, 0.20)",
  fontSize: 300,
  fontFamily: "Halcom",
  fontWeight: "900",
  zIndex: "-1",
};

const previewBox = {
  pb: "20px",
  mt: "-430px",
};

const downloadBtn = {
  ...transcriptPreviewDownload,
  border: "1px solid #919395 !important",
  "& .MuiCircularProgress-root": {
    color: "black",
  },
};

export {
  transcriptPreviewClose,
  previewText,
  previewBox,
  transcriptPreviewDownload,
  downloadBtn,
};
