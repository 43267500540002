import React, { useState, useEffect, useContext } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { loader } from "../../sharedStyles";
import {
  transcriptPreviewClose,
  transcriptPreviewDownload,
  previewText,
  previewBox,
  downloadBtn,
} from "./TranscriptPreview.style";
import TranscriptService from "../../../service/transcriptService";
import axios from "../../../utils/axios-util";
import { SnackbarContext } from "../../../context/SnackbarContext";

export default function TranscriptPreview({ handleClose }) {
  const [previewHtml, setPreviewHtml] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const transcriptPdfsEndpoint = "/api/transcripts_pdfs";
  const [downloadLoader, setDownloadLoader] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const getPreview = async () => {
    setLoading(true);

    const response = await TranscriptService.fetchPreview({
      student_id: params.student_slug,
    });

    setPreviewHtml(response.html_content);
    setLoading(false);
  };

  useEffect(() => {
    getPreview();
  }, []);

  if (loading) {
    return <CircularProgress sx={loader} size={100} />;
  }

  function snackBarError(message = "Something went wrong.") {
    snackbarContext.setSnackbar({
      severity: "error",
      open: true,
      message,
    });
  }

  const handleDownload = async () => {
    const filename = `${params.school_id}_${params.student_slug}`;
    const data = {
      student_id: params.student_slug,
      filename,
    };
    setDownloadLoader(true);

    try {
      axios({
        url: `${transcriptPdfsEndpoint}/download`,
        method: "POST",
        responseType: "blob",
        data,
      }).then(
        async (response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement("a");
          link.href = href;
          let apiFilename = response.headers["content-disposition"]
            ?.split(";")[1]
            ?.split("=")[1]
            ?.replace(/['"]+/g, "");
          if (!apiFilename) {
            apiFilename = params.filename;
          }
          link.setAttribute("download", apiFilename);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);

          setDownloadLoader(false);
          snackbarContext.setSnackbar({
            severity: "success",
            open: true,
            message: "Transcript downloaded.",
          });
        },
        async (e) => {
          const errorMessage = JSON.parse(
            await e.response?.data?.text()
          )?.message;
          setDownloadLoader(false);
          snackBarError(errorMessage);
        }
      );
    } catch (e) {
      setDownloadLoader(false);
      snackBarError();
    }
  };

  return (
    <Box sx={previewBox}>
      <Box sx={previewText}>PREVIEW</Box>
      {downloadLoader ? (
        <LoadingButton sx={downloadBtn} loading variant="outlined">
          Download
        </LoadingButton>
      ) : (
        <Button
          variant="outlined"
          sx={transcriptPreviewDownload}
          onClick={() => handleDownload()}
        >
          Download
        </Button>
      )}
      <Button
        variant="outlined"
        sx={transcriptPreviewClose}
        onClick={() => handleClose()}
      >
        Close
      </Button>
      <Box dangerouslySetInnerHTML={{ __html: previewHtml }} />
    </Box>
  );
}
