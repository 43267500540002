import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { pageTabsContainer } from "../../sharedStyles";
import { admissionsContainer } from "./Setup.styles";
import SetupTabs from "./SetupTabs";
import { QuickBarContext } from "../../../context/QuickBarContext";
import schoolYearService from "../../../service/schoolYearService";
import { SnackbarContext } from "../../../context/SnackbarContext";
import admissionProcessService from "../../../service/admissionProcessService";

export default function Setup() {
  const [schoolId] = useOutletContext();
  const quickBarContext = useContext(QuickBarContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSchoolYearId = searchParams.get("school_year");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [schoolYears, setSchoolYears] = useState([]);
  const [targetSchoolYear, setTargetSchoolYear] = useState(null);
  const snackbarContext = React.useContext(SnackbarContext);
  const [cloningData, setCloningData] = React.useState(false);

  const getSchoolYears = async () => {
    const response = await schoolYearService.fetchAll({
      params: {
        school_id: schoolId,
      },
    });
    if (response.data) {
      setSchoolYears(response.data.school_years);
    }
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCloneProcesses = async () => {
    snackbarContext.setSnackbar({
      message: "Cloning processes.",
      severity: "info",
      open: true,
    });
    setCloningData(true);
    admissionProcessService
      .duplicate(currentSchoolYearId, targetSchoolYear.id)
      .then(() => {
        setCloningData(false);
        handleModalClose();
        snackbarContext.setSnackbar({
          message: "Processes cloned.",
          severity: "success",
          open: true,
        });
        searchParams.set("reload", true);
        setSearchParams(searchParams);
      })
      .catch(() => {
        setCloningData(false);
        snackbarContext.setSnackbar({
          message: "Unable to import.",
          severity: "error",
          open: true,
        });
      });
  };

  React.useEffect(() => {
    if (quickBarContext.createNewProcess) {
      navigate(
        `/school/${schoolId}/admissions/setup/process-builder/form?school_year=${searchParams.get(
          "school_year"
        )}`
      );
      quickBarContext.cleanCreateNewProcess();
    }
  }, [quickBarContext.createNewProcess]);

  React.useEffect(() => {
    if (quickBarContext.createNewForm) {
      navigate(
        `/school/${schoolId}/admissions/setup/form-builder?school_year=${searchParams.get(
          "school_year"
        )}`
      );
      quickBarContext.cleanCreateNewForm();
    }
  }, [quickBarContext.createNewForm]);

  React.useEffect(() => {
    if (quickBarContext.duplicateProcesses) {
      handleModalOpen();
      quickBarContext.cleanDuplicateProcesses();
    }
  }, [quickBarContext.duplicateProcesses]);

  React.useEffect(() => {
    getSchoolYears();
  }, []);

  return (
    <>
      <Container maxWidth={false} variant="header" sx={{ py: "0 !important" }}>
        <Box sx={pageTabsContainer}>
          <SetupTabs />
        </Box>
      </Container>

      <Box sx={admissionsContainer}>
        <Outlet context={[schoolId]} />
      </Box>

      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Clone processes from another school year
          </Typography>
          <Box
            pt={2}
            sx={{
              display: "flex",
              mt: "15px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="school_years">
                {schoolYears.length === 0
                  ? "No other school years"
                  : "School Years"}
              </InputLabel>
              <Select
                defaultValue=""
                disabled={schoolYears.length === 0}
                label="School Years"
                onChange={(e) => setTargetSchoolYear(e.target.value)}
              >
                {schoolYears
                  .filter((sy) => sy.id !== Number(currentSchoolYearId))
                  .map((_schoolYear) => (
                    <MenuItem value={_schoolYear} key={_schoolYear.id}>
                      {`${_schoolYear.name}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box ml={2}>
              <Button
                sx={{
                  backgroundColor: "#2196F3",
                  padding: "2px 15px",
                  height: "40px",
                  textTransform: "none",
                }}
                disabled={cloningData}
                variant="contained"
                onClick={() => handleCloneProcesses()}
              >
                Clone
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
