import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { formCheckBox, mt25 } from "../sharedStyles";
import { termsContainer, termsTitle } from "./Terms.styles";
import { renderError } from "../../utils/constants/forms";

export default function Terms({ terms, termId, klassTerms, isEdit }) {
  const [selectedTerms, setSelectedTerms] = useState([]);
  const {
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useFormContext();

  const isDisabled = (term) =>
    isEdit &&
    term.klass_schedules.length > 0 &&
    klassTerms.map((kt) => kt.term_id).includes(term.id);

  useEffect(() => {
    if (selectedTerms.length === 0) {
      setError("term_ids", {
        type: "manual",
        message: "Please select at least one term.",
      });
    } else {
      clearErrors("term_ids");
    }
    setValue("term_ids", selectedTerms);
  }, [selectedTerms]);

  useEffect(() => {
    if (termId) {
      const termsList = [
        ...new Set([
          ...selectedTerms,
          termId,
          ...klassTerms.map((kt) => kt.term_id),
        ]),
      ];
      setSelectedTerms(termsList);
    }
  }, []);

  return (
    <Box sx={mt25}>
      <Typography sx={termsTitle}>Terms</Typography>

      <Box sx={termsContainer}>
        {terms.map((term) => (
          <FormControl fullWidth sx={formCheckBox} key={term.id}>
            <FormControlLabel
              control={
                <Tooltip
                  placement="bottom"
                  title={
                    isDisabled(term)
                      ? "There is a class scheduled for this term."
                      : ""
                  }
                  key={term.id}
                >
                  <span>
                    <Checkbox
                      defaultChecked={
                        klassTerms
                          ?.map((kt) => kt.term_id)
                          ?.includes(term.id) || termId === term.id
                      }
                      disabled={isDisabled(term)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTerms([...selectedTerms, term.id]);
                        } else {
                          setSelectedTerms(
                            selectedTerms.filter(
                              (selectedTerm) => selectedTerm !== term.id
                            )
                          );
                        }
                      }}
                    />
                  </span>
                </Tooltip>
              }
              label={<Typography>{term.name}</Typography>}
            />
          </FormControl>
        ))}
      </Box>
      {errors.term_ids && renderError(errors.term_ids.message)}
    </Box>
  );
}
