import axios from "../utils/axios-util";

const admissionProcessesEndpoint = "/api/admission_process";

const create = async (processData, schoolYearId) => {
  const { data } = await axios.post(admissionProcessesEndpoint, processData, {
    params: { school_year_id: schoolYearId },
  });
  if (!data) {
    console.error("Error creating process");
    return null;
  }
  return data;
};

const fetchAllProcesses = async (schoolYearId) => {
  const { data } = await axios.get(admissionProcessesEndpoint, {
    params: { school_year_id: schoolYearId },
  });
  if (!data) {
    console.error("Error fetching process list");
    return [];
  }
  return data;
};

const fetchProcess = async (processId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/${processId}`
  );
  if (!data) {
    console.error("Error fetching process");
    return {};
  }
  return data;
};

const updateProcess = async (process) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/${process.id}`,
    process
  );
  if (!data) {
    console.error("Error updating process");
    return {};
  }
  return data;
};

const createProcessInstance = async (formResponseId, processId) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/create_process_instance`,
    {
      form_response_id: formResponseId,
      process_id: processId,
    }
  );
  if (!data) {
    console.error("Error creating process instance");
    return null;
  }
  return data;
};

const switchProcessInstance = async (
  currentProcessId,
  newProcessId,
  studentId
) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/switch_process_instance`,
    {
      process_id: currentProcessId,
      new_process_id: newProcessId,
      student_id: studentId,
    }
  );
  if (!data) {
    console.error("Error creating process instance");
    return null;
  }
  return data;
};

const createMultipleProcessInstance = async (processId, studentIds) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/create_multiple_process_instance`,
    {
      process_id: processId,
      student_ids: studentIds,
    }
  );
  if (!data) {
    console.error("Error creating process instance");
    return null;
  }
  return data;
};

const fetchAllProcessesInstances = async (schoolYearId, step, query, page) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/process_instances`,
    {
      params: { school_year_id: schoolYearId, step, query, page },
    }
  );
  if (!data) {
    console.error("Error fetching process list");
    return [];
  }
  return data;
};

const fetchAdmissionProcessInstanceDetails = async (studentId, processId) => {
  const params = {};
  if (studentId) params.student_id = studentId;
  if (processId) params.process_id = processId;
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/process_instances_details`,
    {
      params,
    }
  );
  if (!data) {
    console.error("Error fetching process list");
    return [];
  }
  return data;
};

const startElementInstance = async (elementInstanceId) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/start_element_instance`,
    { element_instance_id: elementInstanceId }
  );
  if (!data) {
    console.error("Error updating process");
    return {};
  }
  return data;
};

const uploadElementInstanceFile = async (params) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/upload_element_instance_file`,
    params
  );
  if (!data) {
    console.error("Error uploading file");
    return null;
  }
  return data;
};

const deleteElementInstanceFile = async (params) => {
  const { data } = await axios.delete(
    `${admissionProcessesEndpoint}/delete_element_instance_file`,
    { params }
  );
  if (!data) {
    console.error("Error deleting file");
    return null;
  }
  return data;
};

const elementInstances = async (studentId, elementType, schoolYearId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/element_instances`,
    {
      params: {
        student_id: studentId,
        element_type: elementType,
        school_year_id: schoolYearId,
      },
    }
  );
  if (!data) {
    console.error("Error fetching process list");
    return [];
  }
  return data;
};

const postNotes = async (notesData) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/post_notes`,
    notesData
  );
  if (!data) {
    console.error("Error creating notes.");
    return null;
  }
  return data;
};

const notes = async (queryParams) => {
  const { data } = await axios.get(`${admissionProcessesEndpoint}/notes`, {
    params: queryParams,
  });
  if (!data) {
    console.error("Error fetching notes list");
    return [];
  }
  return data;
};

const flagNote = async (notesData) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/flag_note`,
    notesData
  );
  if (!data) {
    console.error("Error updating notes.");
    return null;
  }
  return data;
};

const markElementApproved = async (elementInstanceId) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/mark_element_approved`,
    { element_instance_id: elementInstanceId }
  );
  if (!data) {
    console.error("Error updating element.");
    return null;
  }
  return data;
};

const markElementCompleted = async (elementInstanceId) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/mark_element_completed`,
    { element_instance_id: elementInstanceId }
  );
  if (!data) {
    console.error("Error updating element.");
    return null;
  }
  return data;
};

const pendingApprovalsAndActionCount = async (schoolYearId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/pending_approval_and_actions_counts`,
    { params: { school_year_id: schoolYearId } }
  );
  if (!data) {
    console.error("Error fetching notes list");
    return [];
  }
  return data;
};

const pendingApprovals = async (schoolYearId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/pending_approvals`,
    { params: { school_year_id: schoolYearId } }
  );
  if (!data) {
    console.error("Error fetching notes list");
    return [];
  }
  return data;
};

const pendingActions = async (schoolYearId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/pending_actions`,
    { params: { school_year_id: schoolYearId } }
  );
  if (!data) {
    console.error("Error fetching notes list");
    return [];
  }
  return data;
};

const fetchFormElements = async (schoolYearId) => {
  const { data } = await axios.get(
    `${admissionProcessesEndpoint}/form_element_list`,
    { params: { school_year_id: schoolYearId } }
  );
  if (!data) {
    console.error("Error fetching notes list");
    return [];
  }
  return data;
};

const startNewProcess = async (schoolYearId, studentId) => {
  const { data } = await axios.post(
    `${admissionProcessesEndpoint}/start_new_process`,
    { school_year_id: schoolYearId, student_id: studentId }
  );
  if (!data) {
    console.error("Error starting new process");
    return null;
  }
  return data;
};

const toggleProcessInstanceVisibility = async (processInstanceId) => {
  const { data } = await axios.put(
    `${admissionProcessesEndpoint}/toggle_process_instance_visibility`,
    { process_instance_id: processInstanceId }
  );
  if (!data) {
    console.error("Error updating process instance visibility");
    return null;
  }
  return data;
};

const updateAdmissionGrade = async (data) =>
  axios.put(`${admissionProcessesEndpoint}/update_admission_grade`, data);
const duplicate = async (currentSchoolYearId, targetSchoolYearId) => {
  const { data } = await axios.post(`${admissionProcessesEndpoint}/duplicate`, {
    current_school_year_id: currentSchoolYearId,
    target_school_year_id: targetSchoolYearId,
  });
  if (!data) {
    console.error("Error duplicating process");
    return null;
  }
  return data;
};

export default {
  create,
  fetchAllProcesses,
  fetchProcess,
  updateProcess,
  createProcessInstance,
  fetchAllProcessesInstances,
  fetchAdmissionProcessInstanceDetails,
  startElementInstance,
  uploadElementInstanceFile,
  deleteElementInstanceFile,
  createMultipleProcessInstance,
  elementInstances,
  postNotes,
  notes,
  flagNote,
  markElementApproved,
  markElementCompleted,
  pendingApprovalsAndActionCount,
  pendingApprovals,
  pendingActions,
  fetchFormElements,
  switchProcessInstance,
  startNewProcess,
  toggleProcessInstanceVisibility,
  updateAdmissionGrade,
  duplicate,
};
