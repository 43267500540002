import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { formButtons, formHeader, formTitle } from "../../sharedStyles";
import {
  allGradeLevelsGenerateBtn,
  gradeLevelDownloadBtn,
  studentGenerateBtn,
  transcriptBox,
  box,
  selectBox,
  schoolBox,
  selectForm,
  title,
  checkbox,
  transcriptDrawer,
} from "./GenerateTranscriptRecord.style";
import transcriptService from "../../../service/transcriptService";
import { QuickBarContext } from "../../../context/QuickBarContext";

export default function GenerateTranscriptRecord({
  handleGenerateTranscriptDrawer,
  setSnackBarOpen,
}) {
  const [allGradeLevels, setAllGradeLevels] = useState(false);
  const [gradeLevel, setGradeLevel] = useState(null);
  const [student, setStudent] = useState(null);

  const [gradeLevels, setGradeLevels] = useState([]);
  const [students, setStudents] = useState([]);

  const [showAllGradeLevelsLoader, setShowAllGradeLevelsLoader] =
    useState(false);
  const [showGradeLevelLoader, setShowGradeLevelLoader] = useState(false);
  const [showStudentLoader, setShowStudentLoader] = useState(false);

  const [disableAllGradeLevelsGenerate, setDisableAllGradeLevelsGenerate] =
    useState(false);
  const [disableGradeLevelGenerate, setDisableGradeLevelGenerate] =
    useState(true);
  const [disableGradeLevelSelect, setDisableGradeLevelSelect] = useState(false);
  const [disableStudentGenerate, setDisableStudentGenerate] = useState(true);
  const [disableStudentSelect, setDisableStudentSelect] = useState(false);

  const studentSlug = useParams().student_slug;
  const quickbarContext = useContext(QuickBarContext);

  const handleStudentChange = (event, sortedStudents = []) => {
    let value = event?.target?.value;
    if (!value) {
      value = event;
    }

    let selectedStudent = students.find((s) => s.id === value);
    if (!selectedStudent) {
      selectedStudent = sortedStudents.find((s) => s.id === value);
    }

    setStudent(selectedStudent);
    setDisableStudentGenerate(false);
    setGradeLevel(null);
    setDisableGradeLevelGenerate(true);
    setAllGradeLevels(false);
  };

  useEffect(() => {
    (async () => {
      const response = await transcriptService.fetchStudents();

      if (response.data) {
        // filter unique students
        const uniqueStudents = response.data.filter(
          (ele, index) =>
            response.data.findIndex((obj) => obj.id === ele.id) === index
        );
        const sortedStudents = uniqueStudents.sort((a, b) =>
          a.last_name > b.last_name ? 1 : -1
        );
        setStudents(sortedStudents);
        // set default selected student
        const selectedStudent = sortedStudents.find(
          (s) => s.slug === studentSlug
        );

        if (selectedStudent) {
          handleStudentChange(selectedStudent.id, sortedStudents);
        }

        // get grade levels from students
        // eslint-disable-next-line no-shadow
        let gradeLevels = response.data.map(
          // eslint-disable-next-line no-shadow
          (student) => student.grade_level
        );

        // get uniq grades and sorting
        gradeLevels = [...new Set(gradeLevels)].sort();
        setGradeLevels(gradeLevels);
      }
    })();
  }, []);

  function showLoaderAndSelect(value, downloadOption) {
    if (downloadOption === "student") {
      setShowStudentLoader(value);
    } else if (downloadOption === "grade_level") {
      setShowGradeLevelLoader(value);
    } else if (downloadOption === "all_grade_levels") {
      setShowAllGradeLevelsLoader(value);
    } else {
      setShowStudentLoader(value);
      setShowGradeLevelLoader(value);
      setShowAllGradeLevelsLoader(value);
    }
    setDisableStudentSelect(value);
    setDisableGradeLevelSelect(value);
    setDisableAllGradeLevelsGenerate(value);
  }

  function handleAllGradeLevelsGenerate() {
    setAllGradeLevels(true);
    setDisableAllGradeLevelsGenerate(true);
    setGradeLevel(null);
    setDisableGradeLevelGenerate(true);
    setStudent(null);
    setDisableStudentGenerate(true);
  }

  const handleGradeLevelChange = (event) => {
    setGradeLevel(event.target.value);
    setDisableGradeLevelGenerate(false);
    setStudent(null);
    setDisableStudentGenerate(true);
    setAllGradeLevels(false);
  };

  function snackBarError(message = "Something went wrong.") {
    setSnackBarOpen({
      severity: "error",
      open: true,
      message,
    });
  }

  const generateTranscript = async (generateOption) => {
    try {
      showLoaderAndSelect(true, generateOption);
      const response = await transcriptService.generateTranscriptApi({
        student_id: student?.id,
        grade_level: gradeLevel,
        generate_option: generateOption,
      });

      showLoaderAndSelect(false);
      if (response.data) {
        setSnackBarOpen({
          severity: "success",
          open: true,
          message: "Transcripts generated.",
        });
        quickbarContext.fireLoadTranscripts();
      } else {
        snackBarError();
      }
    } catch (e) {
      showLoaderAndSelect(false);
      const errorMessage = e?.response?.data?.message;
      if (errorMessage.includes("No student")) {
        snackBarError(errorMessage);
      } else {
        snackBarError();
      }
    }
  };

  return (
    <Box sx={transcriptDrawer}>
      <Stack direction="row" sx={formHeader}>
        <Typography sx={formTitle}>Generate Transcript Records</Typography>
        <Stack direction="row">
          <Button
            sx={formButtons}
            onClick={() => handleGenerateTranscriptDrawer(false)}
          >
            Close
          </Button>
        </Stack>
      </Stack>

      <Box sx={transcriptBox}>
        {/* generate for all grade levels (9-12) */}
        <Box sx={[box, schoolBox]}>
          <Box sx={checkbox}>{allGradeLevels && <CheckIcon />}</Box>
          <Typography sx={title}>All grade levels (9-12)</Typography>
          {showAllGradeLevelsLoader ? (
            <LoadingButton
              sx={allGradeLevelsGenerateBtn}
              loading
              variant="outlined"
            >
              Generate
            </LoadingButton>
          ) : (
            <Button
              disabled={disableAllGradeLevelsGenerate}
              variant="contained"
              sx={allGradeLevelsGenerateBtn}
              onClick={() => {
                handleAllGradeLevelsGenerate();
                generateTranscript("all_grade_levels");
              }}
            >
              Generate
            </Button>
          )}
        </Box>

        {/* generate for a grade level */}
        <Box sx={box}>
          <Box sx={checkbox}>{gradeLevel && <CheckIcon />}</Box>
          <Typography sx={title}>Grade Level</Typography>
          {showGradeLevelLoader ? (
            <LoadingButton
              sx={gradeLevelDownloadBtn}
              loading
              variant="outlined"
            >
              Generate
            </LoadingButton>
          ) : (
            <Button
              disabled={disableGradeLevelGenerate}
              variant="contained"
              sx={gradeLevelDownloadBtn}
              onClick={() => generateTranscript("grade_level")}
            >
              Generate
            </Button>
          )}
        </Box>
        <Box sx={selectBox}>
          <FormControl sx={selectForm}>
            <InputLabel id="grade-level-select-label">
              Select Grade Level
            </InputLabel>
            <Select
              disabled={disableGradeLevelSelect}
              labelId="grade-level-select-label"
              id="grade-level-select"
              value={gradeLevel || ""}
              label="Select Grade Level"
              onChange={handleGradeLevelChange}
            >
              {gradeLevels.map((gl) => (
                <MenuItem key={gl} value={gl}>
                  {gl}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* generate for a student */}
        <Box sx={box}>
          <Box sx={checkbox}>{student && <CheckIcon />}</Box>
          <Typography sx={title}>Student</Typography>
          {showStudentLoader ? (
            <LoadingButton sx={studentGenerateBtn} loading variant="outlined">
              Generate
            </LoadingButton>
          ) : (
            <Button
              disabled={disableStudentGenerate}
              variant="contained"
              sx={studentGenerateBtn}
              onClick={() => generateTranscript("student")}
            >
              Generate
            </Button>
          )}
        </Box>
        <Box sx={selectBox}>
          <FormControl sx={selectForm}>
            <InputLabel id="student-select-label">Select Student</InputLabel>
            <Select
              disabled={disableStudentSelect}
              labelId="student-select-label"
              id="student-select"
              value={student ? student.id : ""}
              label="Select Student"
              onChange={handleStudentChange}
            >
              {students.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {`${s.last_name}, ${s.first_name}`}
                </MenuItem>
              ))}
              {students.length === 0 && <MenuItem>No students.</MenuItem>}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}
