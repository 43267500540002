import React from "react";
import { Route } from "react-router-dom";
import StudentInfoDashboard from "../components/StudentInfo/Dashboard/StudentInfoDashboard";
import StudentInfoProfile from "../components/StudentInfo/Profile/StudentInfoProfile";
import StudentInfoMessages from "../components/StudentInfo/Messages/StudentInfoMessages";
import StudentInfoMedical from "../components/StudentInfo/Medical/StudentInfoMedical";
import StudentInfoAccount from "../components/StudentInfo/Account/StudentInfoAccount";
import StudentInfoEvents from "../components/StudentInfo/Events/StudentInfoEvents";
import StudentInfoPortfolio from "../components/StudentInfo/Portfolio/StudentInfoPortfolio";
import StudentInfo from "../views/StudentInfo";
import Conversation from "../components/StudentInfo/Messages/Conversation";
import StudentTranscript from "../components/StudentInfo/Transcripts/StudentTranscript";
import StudentInfoCommunityService from "../components/StudentInfo/CommunityService/StudentInfoCommunityService";

export default [
  <Route
    path="/school/:school_id/students/:student_slug"
    element={<StudentInfo />}
    key={0}
  >
    <Route
      path="/school/:school_id/students/:student_slug/account"
      element={<StudentInfoAccount />}
      key={2}
    />
    <Route
      path="/school/:school_id/students/:student_slug/profile"
      element={<StudentInfoProfile />}
      key={3}
    />
    <Route
      path="/school/:school_id/students/:student_slug/medical"
      element={<StudentInfoMedical />}
      key={4}
    />
    <Route
      path="/school/:school_id/students/:student_slug/academics"
      element={<StudentInfoDashboard />}
      key={5}
    />
    <Route
      path="/school/:school_id/students/:student_slug/events"
      element={<StudentInfoEvents />}
      key={6}
    />
    <Route
      path="/school/:school_id/students/:student_slug/messages"
      element={<StudentInfoMessages />}
      key={7}
    />
    <Route
      path="/school/:school_id/students/:student_slug/messages/:conversation_key"
      element={<Conversation />}
      key={7}
    />
    <Route
      path="/school/:school_id/students/:student_slug/reports"
      element={<StudentInfoDashboard />}
      key={8}
    />
    <Route
      path="/school/:school_id/students/:student_slug/portfolio"
      element={<StudentInfoPortfolio />}
      key={9}
    />
    <Route
      path="/school/:school_id/students/:student_slug/transcripts"
      element={<StudentTranscript />}
      key={9}
    />
    <Route
      path="/school/:school_id/students/:student_slug/community-service"
      element={<StudentInfoCommunityService />}
      key={10}
    />
  </Route>,
];
