import axios from "../utils/axios-util";

const guardiansEndpoint = "/api/guardians";

const postGuardian = async (body) => axios.post(`${guardiansEndpoint}`, body);

const updateGuardian = async (guardianId, body) =>
  axios.put(`${guardiansEndpoint}/${guardianId}`, body);

const deleteGuardian = async (guardianId, studentId) =>
  axios.delete(`${guardiansEndpoint}/${guardianId}`, {
    params: { student_id: studentId },
  });

const fetchGuardian = async (guardianId, studentId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${guardianId}?&student_id=${studentId}`
  );
  if (!data) {
    console.error("Error fetching user from the API");
    return [];
  }
  return data;
};

const fetchGuardianKids = async (id) => {
  const { data } = await axios.get(`${guardiansEndpoint}/${id}/kids`);
  return data;
};

const searchGuardians = async (firstName, lastName, email) => {
  const queryParams = new URLSearchParams();

  if (firstName) queryParams.append("first_name", firstName);
  if (lastName) queryParams.append("last_name", lastName);
  if (email) queryParams.append("email", email);

  const queryString = queryParams.toString();
  const url = `/api/search_guardians?${queryString}`;

  const { data } = await axios.get(url);
  return data;
};

const addGuardianToStudent = async (body) => {
  const { data } = await axios.post(`/api/add_guardian_to_student`, body);
  return data;
};

const fetchGuardianKidInfo = async (id, kidId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/info`
  );
  return data;
};

const fetchGuardianKidSchedule = async (id, kidId, date) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/schedule?date=${date}`
  );
  return data;
};

const fetchGuardianKidMessages = async (params) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${params.params.guardianId}/kids/${params.params.kidId}/messages`,
    params
  );
  return data;
};

const fetchGuardianRecipients = async (id) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/messages/recipients`
  );
  return data;
};

const fetchGuardianKidGrades = async (id, kidId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/grades`
  );
  return data;
};

const fetchGuardianKidClasses = async (id, kidId, termId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/classes?term_id=${termId}`
  );
  return data;
};

const fetchGuardianKidCurrentTerm = async (id, kidId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/current_term`
  );
  return data;
};

const fetchGuardianKidProfile = async (id, kidId) => {
  const { data } = await axios.get(
    `${guardiansEndpoint}/${id}/kids/${kidId}/profile`
  );
  return data;
};

const updatePortalAccess = async (id, params) => {
  const { data } = await axios.patch(
    `${guardiansEndpoint}/${id}/update_portal_access`,
    params
  );
  return data;
};

const fetchGuardianLanguage = async (id) => {
  const { data } = await axios.get(`${guardiansEndpoint}/${id}/language`);
  return data;
};

const updateEmail = async (id, params) => {
  const { data } = await axios.patch(
    `${guardiansEndpoint}/${id}/update_email`,
    params
  );
  return data;
};

export {
  postGuardian,
  updateGuardian,
  fetchGuardian,
  fetchGuardianKids,
  searchGuardians,
  addGuardianToStudent,
  fetchGuardianKidInfo,
  fetchGuardianKidSchedule,
  fetchGuardianKidMessages,
  fetchGuardianRecipients,
  fetchGuardianKidGrades,
  fetchGuardianKidClasses,
  fetchGuardianKidCurrentTerm,
  fetchGuardianKidProfile,
  deleteGuardian,
  updatePortalAccess,
  fetchGuardianLanguage,
  updateEmail,
};
