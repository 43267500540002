import { generateGUID } from "../../../../../../utils/constants/utils";
import Properties from "./Properties";

export default class FieldBase {
  constructor(_field) {
    const field = _field || {};
    this.id = field.id || null;
    this.fieldId =
      field.fieldId ||
      field.field_id ||
      `_${generateGUID().replaceAll("-", "_")}`;
    this.label = field.label || "";
    this.type = field.type || field.field_type || "";
    this.order = field.order || 0;
    this.dataType = field.dataType || field.data_type || "";
    this.isVisible = field.isVisible || true;
    this.isValid = field.isValid || true;
    this.sourceFieldId = field.sourceFieldId || field.source_field_id || null;
    this.sourceFormId = field.sourceFormId || field.source_form_id || null;
    this.isDeleted = false;
    this.properties = new Properties(field.properties);
    this.removable = !this.properties.dbRequiredField;
    this.editable = field.editable || true;
    this.response = field.response || null;
    this.importTable = field.importTable || field.import_table || null;
    this.importColumn = field.importColumn || field.import_column || null;
    this.customFieldId = field.customFieldId || field.custom_field_id || null;
    this.customFieldResponseType =
      field.customFieldResponseType || field.custom_field_response_type || null;
    this.i = field.i || this.fieldId || field.field_id;
    this.x = field.x || 0;
    this.y = field.y || 0;
    this.w = field.w || 4;
    this.h = field.h || 1;
    this.static = false;
    this.moved = false;
    this.isDraggable = true;
    this.draggableItemStyle = { height: "fit-content !important" };
    this.fieldContainerStyle = {};
    this.isCustomField = field.isCustomField || false;
  }

  serialize() {
    return {
      id: this.id,
      field_id: this.fieldId,
      properties: this.properties,
      label: this.label?.trim(),
      field_type: this.type,
      source_field_id: this.sourceFieldId,
      source_form_id: this.sourceFormId,
      data_type: this.dataType,
      is_deleted: this.isDeleted,
      order: this.order,
      removable: this.removable,
      editable: this.editable,
      response: this.response,
      import_table: this.importTable,
      import_column: this.importColumn,
      custom_field_response_type: this.customFieldResponseType,
      custom_field_id: this.customFieldId,
      x: this.x,
      y: this.y,
      w: this.w,
      h: this.h,
      isCustomField: this.isCustomField,
    };
  }
}
